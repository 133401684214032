.our-service-page-main{
  max-width: 1400px;
  margin: auto;
}
.our-service-page-heading-1 {
  font-size: 2rem;
  font-weight: 600;
  margin: 30px 80px 10px;
}
.our-service-page-heading-1 span {
  color: red;
}
.our-service-page-text {
  margin: 0 110px;
  font-size: 1.2rem;
}

.our-s-p-col-1 {
  padding: 30px 0;
  width: 90%;
  margin: auto;
  gap: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.our-s-p-col-1 > div {
  padding: 30px;
  background-color: black;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 10px;
  border-radius: 20px;
  cursor: pointer;
}
.our-s-p-col-1 > div:hover {
  box-shadow: rgba(255, 255, 255, 0.699) 0px 0px 20px;
}

.our-s-p-col-h {
  color: red;
  font-weight: 600;
  font-size: 1.3rem;
}
.our-s-p-col-p {
  padding: 5px 0 0 20px;
}

@media only screen and (max-width: 600px) {
  .our-service-page-heading-1 {
    font-size: 1.5rem;
    margin: 30px 20px 10px;
  }

  .our-service-page-text {
    margin: 0 15px 0 30px;
    font-size: 0.9rem;
  }
  .our-s-p-col-1 {
    grid-template-columns: 1fr;
    padding: 15px 0 40px;
  }

  .our-s-p-col-1 > div {
    padding: 20px;
  }

  .our-s-p-col-h {
    font-size: 1.1rem;
  }

  .our-s-p-col-p {
    font-size: 0.8rem;
    padding: 5px 0 0 10px;
  }
}
