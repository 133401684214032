.price-page-form{
    position: fixed;
    background: rgb(33, 33, 33);
    /* top: 30px; */
    height: 100Vh;
    width: 100%;
    display: flex;
    /* flex-direction: column; */
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 2;
}
.price-page-form-close{
    position: absolute;
    top: 20px;
    /* left: 20px; */
}
.price-page-form-close span{
    font-size: 2.5rem;
    color: red;
}
.price-page-form>div{
    width: 100%;
    display: flex;
    flex-direction: column;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
}
.price-page-form-heading-1{
    font-size: 1.9rem;
    margin-bottom: 10px;
    font-weight: 600;
    text-align: center;
}
.price-page-form-heading-1 span{
    color: red;
}
.price-page-form-heading-2{
    color: red;
    font-size: 1.3rem;
    font-weight: 500;
    margin-bottom: 30px;
    text-align: center;
    
}
.price-page-form>div>form{
    width: 600px;
}

@media only screen and (max-width: 600px) {
    .price-page-form>div>form{
        width: 100%;
        padding: 20px;
    }

.price-page-form-close span{
    font-size: 2rem;
}
    
.price-page-form-heading-1{
    font-size: 1.3rem;
    margin-bottom: 0px;
}
.price-page-form-heading-2{
    font-size: 1rem;
    margin-bottom: -20px;
}

}



