/* ---------------------------------------------- */

.blog-nav-main {
  background-color: black;
  height: 80px;
  box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 10px;
}

.blog-nav {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  max-width: 1300px;
  margin: auto;
  padding: 25px 0;
  position: relative;
}

.blog-nav-col-1 > img {
  width: 180px;
  background-color: white;
  margin-top: -25px;
  padding: 15px 25px 20px;
  border-radius: 0 0 30px 30px;
  z-index: 2;
}

.blog-nav-col-3 {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}
.blog-nav-col-3 a {
  background-color: white;
  color: black;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}
.blog-nav-col-3 a:hover {
  background-color: red;
  color: white;
  transition: 0.3s;
}
.blog-nav-col-2 {
  display: flex;
  justify-content: center;
  gap: 50px;
}
.blog-nav-col-2 > a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  height: 20px;
}
.blog-nav-col-2 > a:hover {
  color: red;
}
.blog-nav-col-2-red{
  color: red !important;
}

.blog-nav-button {
  display: none;
}

/* ----------------------------------------------------------- */

.our-blog-header {
  max-width: 1200px;
  margin: auto;
  background: url("../../images/blog-bg.jpeg");
  background-position: right;
  background-size: cover;
}
.our-blog-header-bg {
  /* background-color: rgba(0, 0, 0, 0.1); */
}

.our-blog-header-content {
  padding: 140px 100px;
}
.our-blog-header-content > div {
  max-width: 450px;
  display: inline-block;
  text-align: center;
  padding: 50px;
  background-color: white;
  color: black;
}

.our-blog-header-heading {
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}
.our-blog-header-heading > span {
  color: red;
}
.our-blog-header-text {
  margin-top: 10px;
  font-size: 1.4rem;
  font-weight: 500;
}

/* ----------------------------------------------------------- */

.our-blog-page {
  max-width: 1300px;
  margin: auto;
}

.our-blog-page-heading {
  text-align: center;
  font-size: 2.5rem;
  font-weight: 600;
  margin: 40px 0;
}
.our-blog-page-heading > span {
  background-color: black;
  padding: 5px 80px;
  box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 15px;
}
.our-blog-page-heading > span > span {
  color: red;
}

.our-blog-page-main {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 50px;
  margin: 50px auto;
}

.our-blog-page-col {
  display: grid;
  gap: 50px;
}

.our-blog-page-blog {
  display: grid;
  grid-template-columns: 3fr 4fr;
  grid-template-columns: 3fr 3fr;
  gap: 30px;
  background-color: black;
  box-shadow: rgba(255, 255, 255, 0.3) 0px 0px 10px;
  padding: 20px;
  border-radius: 20px;
}

.our-blog-page-blog-img > img {
  width: 100%;
  border-radius: 10px;
  display: flex;
}

.our-blog-page-blog-head {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  padding: 10px 0;
  color: red;
  font-weight: 600;
}

.our-blog-page-blog-heading {
  font-size: 1.3rem;
  font-weight: 600;
}
.our-blog-page-blog-button {
  margin: 20px 0 0px;
}

.our-blog-page-blog-button > a {
  background-color: red;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  font-size: 1.1rem;
}

.our-blog-page-blog-button > a:hover {
  background-color: rgb(173, 10, 10);
  transition: 0.5s;
}

/* col 2 -------------------------------------------------------------- */

.our-blog-page-col-2-fix {
  height: 100%;
}
.our-blog-page-col-2 {
  position: sticky;
  top: 0;
}
.our-blog-page-col-2-col {
  background-color: black;
  padding: 15px;
  margin-bottom: 30px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 0px 10px;
}

.our-blog-page-col-2-col-heading {
  background-color: red;
  text-align: center;
  font-size: 1.2rem;
  padding: 5px;
  font-weight: 600;
}

.our-blog-page-col-2-col-icon {
  display: flex;
  justify-content: space-evenly;
  margin: 20px 0 10px;
}
.our-blog-page-col-2-col-icon > a {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: red;
  text-decoration: none;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 1.4rem;
}
.our-blog-page-col-2-col-icon > a:hover {
  background-color: red;
  color: white;
  transition: 0.3s;
}

.our-blog-page-col-2-content {
  background-color: #111111;
  padding: 15px;
  margin: 20px 0;
}
.our-blog-page-col-2-content > a {
  color: white;
  text-decoration: none;
}
.our-blog-page-col-2-content > a:hover {
  color: red;
  transition: 0.4s;
  cursor: pointer;
}
.our-blog-page-col-2-content-heading {
  font-size: 1.2rem;
  font-weight: 600;
}
.our-blog-page-col-2-content-date {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 600px) {
  .blog-nav {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .blog-nav-col-3 {
    display: none;
  }
  .blog-nav-button {
    display: block;
  }
  .blog-nav-button > i {
    font-size: 1.2rem;
    background-color: white;
    color: black;
    padding: 5px 8px;
  }

  .blog-nav-main {
    background-color: #000000;
    height: 50px;
    box-shadow: rgba(255, 255, 255, 0.4) 0px 0px 10px;
  }

  .blog-nav-col-1 > img {
    width: 150px;
    background-color: white;
    margin-top: -10px;
    padding: 5px 20px 5px;
    border-radius: 0 0 20px 20px;
  }

  .blog-nav-col-2 {
    display: none;
  }
  .blog-nav-col-2-active {
    position: fixed;
    background-color: black;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
    z-index: 1;
  }

  .blog-nav-close-button > i {
    position: fixed;
    top: 20px;
    right: 20px;
    font-size: 2rem;
    background-color: transparent;
    color: white;
  }

  /* ------------------------------------------------------------- */

  .our-blog-header {
    background-position: right;
  }

  .our-blog-header-bg {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .our-blog-header-content {
    padding: 40px 10px;
  }

  .our-blog-header-content > div {
    padding: 50px 0;
    background-color: transparent;
    color: white;
  }

  .our-blog-header-heading {
    font-size: 2rem;
  }
  .our-blog-header-text {
    font-size: 1.2rem;
  }

  /* ------------------------------------------------------------- */
  .our-blog-page-heading {
    font-size: 1.5rem;
    margin: 40px 5px 20px;
  }

  .our-blog-page-heading > span {
    padding: 5px 10px;
    box-shadow: rgba(255, 255, 255, 0.6) 0px 0px 8px;
  }

  .our-blog-page-col {
    padding: 15px;
  }

  .our-blog-page-main {
    grid-template-columns: 1fr;
    margin-top: 20px;
  }

  .our-blog-page-blog {
    grid-template-columns: 1fr;
    border-radius: 15px;
    gap: 10px;
  }

  .our-blog-page-blog-img > img {
    border-radius: 10px;
  }

  .our-blog-page-blog-heading {
    font-size: 1.1rem;
  }
  .our-blog-page-blog-head {
    padding: 5px 0;
    font-size: 1rem;
  }

  .our-blog-page-blog-text {
    font-size: 0.9rem;
  }

  .our-blog-page-blog-button {
    margin: 10px 0;
  }

  .our-blog-page-blog-button > a {
    font-size: 1rem;
    padding: 5px 10px;
  }
}
