.footer-logo{
  background-color: white;
  text-align: center;
  padding: 10px 30px;
  border-radius: 15px;
  box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
}
.footer-logo img{
  /* border-right: solid; */
  /* padding-right: 50px; */
  width: 180px;
  display: flex;
}

.footer-main {
    padding-top: 30px;
    background-color: black;
    color: white;
  }
  
  .footer-main a {
    color: rgb(175, 175, 175);
    text-decoration: none;
  }
  
  /* head */
  .footer-head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 70px;
    padding: 30px 0;
  }
  
  .fh-logo {
    text-align: center;
    border-right: solid 2px gray;
    padding-right: 80px;
  }
  .fh-logo a {
    color: white;
  }
  
  .fh-l-logo {
    font-size: 2rem;
    font-weight: 800;
    font-family: "Poppins";
    font-weight: 600;
  }
  .fh-l-logo span {
    color: red;
  }
  .fh-l-logo span i {
    font-size: 1.8rem;
  }
  
  .fh-l-tagline {
    font-size: large;
    color: rgb(198, 198, 198);
    letter-spacing: 4px;
    word-spacing: 2px;
  }
  
  .fh-address-h {
    font-size: x-large;
    font-weight: bolder;
    color: red;
    font-family: "Nunito";
    font-weight: 800;
  }
  
  .fh-address-p {
    font-size: 1.1rem;
    font-weight: normal;
    max-width: 500px;
  }
  
  .footer-content {
    max-width: 1500px;
    margin: auto;
    padding: 50px ;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  
  .footer-content a:hover {
    color: red;
  }
  
  .fc-c-c {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 180px;
  }
  
  .fc-c-h {
    color: red;
    font-family: nunito;
    font-weight: 800;
    font-size: 1.3rem;
  }
  
  
  .fc-c-text {
    line-height: 25px;
  }
  
  .fc-c-form {
    padding: 3px;
    background: white;
    display: flex;
    justify-content: space-between;
  }
  
  .fc-c-form input {
    border: none;
    padding-left: 10px;
    flex: 1;
    font-size: 1rem;
  }

  .fc-c-form button {
    color: rgb(246, 246, 246);
    border: none;
    background: rgb(225, 0, 0);
    padding: 10px 20px;
    font-weight: bold;
    font-size: 1rem;
  }
  
  .f-bfr > a::before {
    content: "\276f \276f";
    font-size: 0.7rem;
    margin-right: 10px;
    color: red;
    text-decoration: none !important ;
  }
  
  .f-bfr > div::before {
    content: "\2714";
    font-size: 0.7rem;
    margin-right: 10px;
    color: red;
    text-decoration: none !important ;
  }
  
  .f-bfr > div::before {
    margin-right: 13px;
  }
  
  .footer-cc {
    background-color: #0e1012;
    letter-spacing: 1px;
    word-spacing: 2px;
    text-align: center;
    padding: 15px 0;
  }
  
  @media only screen and (max-width: 1000px) {

    
    .footer-content {
      padding: 40px;
      gap: 10px;
    }
    
    .fh-l-logo{
      font-size: 1.8rem;
    }
    
    .fh-l-logo span i {
      font-size: 1.7rem;
    }
  
    .fh-l-tagline{
      font-size: 1rem;
    }
    .footer-head {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;
    }
    
    .fh-logo {
      padding-right: 10px;
    }
    
    .fh-address-p {
      font-size: 1rem;
      display: flex;
      flex-wrap: wrap;
      font-weight: normal;
      justify-content: center;
    }
    
    .footer-cc {
      font-size: .8rem;
    }
    
  }

  @media only screen and (max-width: 600px){

    .footer-logo{
      width: 200px;
      margin: auto;
    }
    .footer-logo img{
      width: 100%;
      border-right: none;
      padding-right: 0px;
      display: flex;
      /* margin: 20px 0; */

    }
    
    
    .footer-head{
      display: grid;
      grid-template-columns: 1fr;
    }

    .footer-head {
      flex-direction: column;
      text-align: center;
      padding: 0 50px;
      gap: 15px;
    }
    .footer-content {
      padding: 40px;
      gap: 10px;
      flex-direction: column;
    }
    .fh-logo {
      padding: 0;
      border-right: none;
    }
    
    .fh-l-logo {
      font-size: 1.5rem;
    }
  
    .fh-l-logo span i {
      font-size: 1.4rem;
    }
  
    .fh-l-tagline {
      font-size: 1rem;
      color: rgb(198, 198, 198);
      letter-spacing: 2px;
      word-spacing: 1px;
    }
  
    .fh-address {
      padding-top: 20px;
      /* border-top: solid 2px rgb(82, 82, 82); */
    }
  
    .fh-address-h {
      font-size: large;
    }
  
    .fh-address-p {
      font-size: 0.9rem;
      padding: 0 0px;
    }

  
    
    .fh-address {
      padding-top: 20px;
      border-top: solid 3px rgb(192, 192, 192);
    }
  

    .fc-c-form input {
      width: 60%;
    }
    
    .footer-cc {
      font-size: .7rem;
      letter-spacing: 0px;
      word-spacing: 1px;
    }
    
    
  } 

  
  @media only screen and (max-width: 4px) {
    .footer-head {
      flex-direction: column;
      text-align: center;
      padding: 0 50px;
      gap: 15px;
    }
    .footer-content {
      padding: 40px;
  
      gap: 10px;
    }
    .fh-logo {
      padding: 0;
      border-right: none;
    }
  
    .fh-l-logo {
      font-size: 1.5rem;
    }
  
    .fh-l-logo span i {
      font-size: 1.4rem;
    }
  
    .fh-l-tagline {
      font-size: 1rem;
      color: rgb(198, 198, 198);
      letter-spacing: 2px;
      word-spacing: 1px;
    }
  
    .fh-address {
      padding-top: 20px;
      border-top: solid 2px rgb(82, 82, 82);
    }
  
    .fh-address-h {
      font-size: large;
    }
  
    .fh-address-p {
      font-size: 0.9rem;
      padding: 0 20px;
    }

    .fc-c-form input {
      width: 60%;
      border: none;
      padding-left: 10px;
    }
    .fc-c-form button{}
    .footer-cc {
      font-size: small;
      letter-spacing: 0px;
      word-spacing: 1px;
    }
  }
  