
  /* -------------------------------------- */

  .keywords{
    max-width: 1200px;
    margin: 50px auto;
  }
  
  .keywords-heading{
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }
  .keywords-heading>div{
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
  }
  .keywords-heading span{
    color: red;
  }
  .keywords-heading>span{
    /* text-decoration: underline; */
    cursor: pointer;
    width: 130px;
    text-align: end;
    font-size: 1.3rem;
    color: #ff2222;

  }
  .keywords-content{
    color: rgb(170, 170, 170);
    margin-top: 20px;
  }
  .keywords-content span{
    color: red;
    cursor: pointer;
  }
  

  @media only screen and (max-width: 600px) {

    
  /* -------------------------------------- */

  .keywords{
    /* width: 100%; */
    margin: 50px auto ;
    padding: 0 20px;
  }
  .keywords-heading{
    display: flex;
    flex-direction: column;
  }
  .keywords-heading>div{
    font-size: 1.8rem;
  }

  .keywords-heading>span{
    cursor: pointer;
    width: 100%;
    text-align: center;
  }
  
  .keywords-content{
    color: rgb(170, 170, 170);
    margin-top: 10px;
    font-size: .9rem;
  }
  .keywords-content span{
    cursor: pointer;
  }
  

  }