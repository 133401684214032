/* ---------- about start ---------- */

.about-page {
  padding: 40px;
  font-family: poppins;
}

.about-increment {
  display: flex;
  justify-content: space-evenly;
}

.about-inc-col {
  text-align: center;
  font-size: large;
  color: rgb(206, 198, 198);
  font-weight: 600;
  font-family: poppins;
}

.about-inc-col span {
  color: red;
  font-size: xx-large;
}
.about-inc-spn {
  color: rgb(159, 159, 159) !important;
}

.about-us {
  font-family: poppins;
  padding: 50px;
}
.about-us-heading {
  font-size: 2.5rem;
  font-weight: 800;
  color: red;
  /* font-family: cursive; */
  font-family: nunito;
}
.about-us-heading-2 {
  font-size: xx-large;
  font-weight: 600;
}
.about-us-heading-2 span {
  color: red;
}
.about-us-heading-3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: rgb(202, 202, 202);
}

.about-us-text {
  padding: 10px 40px;
  text-align: justify;
  display: grid;
  gap: 15px;
}

/* ------------------- about-mission & vision ------------------- */

.about-mission-vision {
  display: flex;
  gap: 50px;
  width: 90%;
  margin: auto;
}

.about-m-v {
  background-color: black;
  padding: 30px 20px;
  border-radius: 30px;
  box-shadow: rgba(255, 255, 255, 0.356) 1px 1px 20px;
}
.about-m-v-heading {
  color: red;
  font-size: 1.5rem;
  font-weight: 600;
}

.about-m-v-text {
  margin-left: 30px;
  color: rgb(190, 190, 190);
}

@media only screen and (max-width: 600px) {
  .about-mission-vision {
    display: grid;
    gap: 20px;
    width: 95%;
    padding-bottom: 40px;
  }

  .about-m-v {
    padding: 20px;
    border-radius: 20px;
  }
  .about-m-v-heading {
    font-size: 1.3rem;
  }

  .about-m-v-text {
    margin-left: 15px;
    font-size: 0.9rem;
  }
}

/* -------------- journey ---------------- */

.about-journey {
  margin: 30px;
  padding: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
  background-color: black;
  box-shadow: rgba(255, 255, 255, 0.2) 1px 1px 20px;

  /* flex-wrap: wrap; */
}

.about-j-head {
  font-size: 1.8rem;
  color: red;
  font-weight: 600;
  width: auto;
  text-align: center;
}

.about-j-text {
  border-left: 2px rgb(147, 147, 147) solid;
  padding-left: 30px;
  color: rgb(214, 214, 214);
  width: 80%;
}

@media only screen and (max-width: 600px) {
.about-journey {
  display: grid;
  padding: 20px;
  gap: 10px;
  margin: 0;
  text-align: center;
}

.about-j-head{
  font-size: 1.7rem;

}

.about-j-head span{
  display: none;
}


.about-j-text {
  border-left: 0;
  padding: 0;
  width: 100%;
  font-size: .9rem;
}


}




/* -------------how work---------- */

.about-how-work {
  /* display: flex;
    align-items: center;
    gap: 30px; */
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  align-items: center;
  width: 85%;
  gap: 50px;
  margin: auto;
  padding: 40px 0;
}

.about-hw-txt-heading {
  font-size: xx-large;
  font-weight: 800;
  color: red;
  font-family: nunito;
}

.about-hw-txt-heading-1 {
  font-weight: 600;
  color: red;
  font-size: 1.2rem;
}

.why-goc-heading-1 {
  color: red;
  font-weight: 600;
  font-size: 2rem;
}

.why-goc-heading-2 {
  color: rgb(255, 255, 255);
  font-weight: 600;
  font-size: 1.3rem;
}
.why-goc-text {
  color: rgb(192, 192, 192);
}

.about-hw-img {
  margin: 50px 0;
  width: 100%;
  border: rgb(91, 91, 91) solid;
  /* background-color: ; */
  padding: 15px;
  border-radius: 25px;
  display: flex;
  margin: 25px 0;
}
.about-hw-img img {
  width: 100%;
  border-radius: 15px;
  border: rgb(52, 52, 52) solid;
}

.about-hw-txt-text {
  text-align: justify;
  padding: 0 20px;
}
.about-hw-txt-points {
  padding: 10px;
  display: grid;
  gap: 20px;
}

.about-hw-txt-points > div::before {
  content: "\2756";
  font-size: 1.2rem;
  margin-right: 10px;
  color: red;
  text-decoration: none !important ;
}

/* ----------choose us----------- */

.about-choose-us {
  /* padding-top: 20px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  /* width: 85%; */
  gap: 50px;
  margin: auto;
  /* text-align: end; */
  padding: 60px 50px;
}

.about-cu-txt-heading {
  font-size: xx-large;
  font-weight: 800;
  color: red;
  font-family: nunito;
}

.about-cu-img {
  width: 100%;
  border: rgb(91, 91, 91) solid;
  padding: 15px;
  border-radius: 25px;
  display: flex;
}
.about-cu-img img {
  width: 100%;
  border-radius: 15px;
  border: rgb(52, 52, 52) solid;
}
.about-cu-txt-text {
  text-align: justify;
  padding: 0 10px;
}

.about-cu-txt-points {
  padding: 10px 20px;
  display: grid;
  gap: 15px;
}
.about-cu-txt-points span {
  color: red;
  font-weight: 600;
}

.about-cu-txt-points > div::before {
  content: "\2756";
  font-size: 1.2rem;
  margin-right: 10px;
  color: red;
  text-decoration: none !important ;
}

.about-our-work {
  /* padding: 0px 0px 100px; */
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  align-items: center;
  gap: 10px;
  /* margin: auto; */
}
.about-our-work-heading {
  font-size: xx-large;
  font-weight: 800;
  color: red;
  font-family: nunito;
}
.about-our-work-heading-2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgb(142, 142, 142);
}

.about-our-work-text {
  text-align: justify;
}

.about-ow-col-2 {
  height: 450px;
  /* height: auto; */
  width: 700px;
  margin: auto;
  display: grid;
  gap: 20px;
  grid-template-areas: "a a a b b b" "a a a b b b" "c c d d e e ";
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  /* grid-template-rows: 30% 30% 37% ; */
}

/* .about-ow-content{
    height: 700px;
    width: 75%;
    margin: auto;
    display: grid;
    gap: 20px;
    grid-template-areas: "a b c" "a d c";
    grid-template-columns: 50% 25% 25%;
    
  } */

.about-ow-col-2 > div {
  border-radius: 25px;
  background-color: rgb(49, 49, 49);
}
.about-ow-col-2 > div:nth-child(1) {
  grid-area: a;
}
.about-ow-col-2 > div:nth-child(2) {
  grid-area: b;
}
.about-ow-col-2 > div:nth-child(3) {
  grid-area: c;
}
.about-ow-col-2 > div:nth-child(4) {
  grid-area: d;
}
.about-ow-col-2 > div:nth-child(5) {
  grid-area: e;
}

.about-ow-col-2 > div > iframe {
  height: 100%;
  width: 100%;
  border-radius: 15px;
}

@media only screen and (max-width: 600px) {
  .about-page {
    padding: 20px;
  }

  .about-increment {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    gap: 20px 0;
  }

  .about-inc-col {
    font-size: small;
  }

  .about-inc-col span {
    font-size: x-large;
  }
  .about-our-work {
    grid-template-columns: 1fr;
  }
  .about-us {
    font-family: poppins;
    padding: 40px 0;
  }
  .about-us-heading {
    font-size: 1.9rem;
  }
  .about-us-heading-2 {
    font-size: 1.4rem;
  }

  .about-us-heading-3 {
    font-size: 1rem;
  }

  .about-us-text,
  .about-our-work-text {
    padding: 5px 5px 5px 15px;
    font-size: small;
    text-align: justify;
    display: grid;
    gap: 10px;
    color: rgb(223, 223, 223);
  }

  .about-how-work,
  .about-choose-us {
    display: none;
    width: 100%;
    /* margin: 10px; */
    grid-template-columns: 1fr;
    /* padding: 20px; */
    gap: 20px;
    padding: 20px 0;
  }

  .about-hw-img {
    padding: 10px;
    margin: 15px 0;
  }

  .why-goc-heading-1 {
    font-size: 1.2rem;
  }

  .why-goc-heading-2 {
    font-size: 0.9rem;
  }
  .why-goc-text {
    font-size: 0.8rem;
  }

  .about-hw-txt-heading-1 {
    /* .about-hw-txt-heading-1{ */
    font-size: 1rem;
  }

  .about-hw-txt-text {
    padding: 0 0px 0 10px;
    /* font-size: .1rem; */
  }

  .about-hw-txt-heading,
  .about-cu-txt-heading {
    font-size: 1.3rem;
  }

  .about-cu-txt-text,
  .about-hw-txt-text {
    font-size: 0.77rem;
    color: rgb(206, 206, 206);
  }

  .about-hw-txt-points,
  .about-cu-txt-points {
    /* padding: 10px; */
    display: grid;
    gap: 5px;
    font-size: 0.8rem;
  }

  .about-our-work-heading {
    font-size: 1.6rem;
  }
  .about-our-work-heading-2 {
    font-size: 0.9rem;
  }
  .about-our-work-text {
    font-size: 0.8rem;
  }
  .about-ow-col-2 {
    margin-bottom: 50px;
    width: 100%;
    height: 50vh;
    height: auto;

    gap: 10px;
    /* grid-template-areas: "a a a b b b" "a a a b b b" "c c d d e e ";
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr; */
    /* grid-template-areas: "a" "b" "c" "d" "e" ; */
    grid-template-areas: "a b" "c c" "c c" "d e";

    grid-template-columns: 1fr 1fr;
  }

  .about-ow-col-2 > div > iframe {
    /* height: 200px; */
    width: 100%;
    border-radius: 15px;
  }

  .ytp-large-play-button {
    display: none !important;
  }

  /* ytp-large-play-button ytp-button ytp-large-play-button-red-bg */

  .ytp-title-channel-logo {
  }
  .ytp-title-link .yt-uix-sessionlink {
  }
}

/* ---------- about end ---------- */
