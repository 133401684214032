.latest-post {
  max-width: 1300px;
  margin:  auto;
  padding: 60px 0;
}

.latest-post-heading {
  font-size: 2.5rem;
  text-align: center;
  font-weight: 500;

  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
  
}

.latest-post-heading > span {
  color: red;
  font-weight: 700;
}

/* ------------------------------------------------- */

.latest-post-content {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  text-align: center;
  margin: 30px 50px;
}

.latest-post-col {
  background-color: black;
  background-color: white;
  box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
  color: black;
  padding-bottom: 20px;
}
.latest-post-col-img > img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.latest-post-col-heading {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 10px 20px;
}

.latest-post-col-text {
  margin: 10px 20px;
  font-size: 0.9rem;
}

.latest-post-col-link {
  color: red;
  text-decoration: none;
}

.latest-post-col-link:hover {
  text-decoration: underline;
  transition: 0.2s ease-out;
  font-weight: 500;
  color: rgb(170, 0, 0);
}

@media only screen and (max-width: 800px) {
  .latest-post {
    margin:  auto;
    padding: 40px 0;
  }

  .latest-post-heading {
    font-size: 2rem;
  }

  /* ------------------------------------------------- */

  .latest-post-content {
      margin:  0px;
      display: flex;
      overflow: auto;
      padding: 20px;
      gap: 30px;
  }

  .latest-post-col {
    padding-bottom: 20px;
  }
  .latest-post-col-img > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }

  .latest-post-col-heading {
    font-size: 1.05rem;
    margin: 10px 10px;
    min-width: 250px;
  }

  .latest-post-col-text {
    margin: 10px 10px;
    font-size: 0.8rem;
  }

  .latest-post-col-link {
    font-size: .9rem;
  }


}
