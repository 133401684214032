.number-form{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.726);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.number-form-main{
    max-width: 550px;
    position: relative;
    padding: 20px;
}

.number-form-close>i{
     position: absolute;
     right: 30px;
     top: 25px;
     font-size: 2.3rem;
     color: rgb(238, 0, 0);
     background-color: white;

     border-radius: 50%;
     
}

@media only screen and (max-width: 600px) {
.number-form-close>i{
    top: 25px;
    right: 10px;
}
    
}

