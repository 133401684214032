.admin-data-header{
    display: flex;
    justify-content: space-between;
    /* justify-content: space-around; */
    position: sticky;
    top: 0;
    background-color: #14171a;
    /* box-shadow: 2px 4px 10px #fff3; */
    align-items: center;
    padding:  5px 150px;

}

.admin-data-heading{
    font-size: 2rem;
    text-transform: capitalize;
    font-weight: 600;
}

.admin-data-filter i{
    font-size: 1rem;
}
.admin-data-filter{
    /* position: relative; */
    padding: 5px 30px;
    font-size: 1.2rem;
    /* border: solid 1px rgb(47, 47, 47); */
    box-shadow: 1px 1px 6px rgba(255, 255, 255, 0.595);
    border-radius: 20px;
}
.admin-data-filter-col{
    background-color: rgb(31, 31, 31);
    font-size: 1rem;
    padding: 10px;
    /* width: 200px; */
    flex-direction: column;
    gap: 10px;
    display: flex;
    
}
.admin-data-filter-col{
    position: absolute;
    /* right: 0; */
}

/* ---------------------------------------------------- */

.admin-data-list{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    padding: 20px;
    gap: 50px 30px;
}

.admin-data-list-col{
    background-color: black;
    box-shadow: 2px 4px 10px #fff3;
    padding: 10px;
}


.admin-data-list-col-header{
    text-align: center;
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 10px;
    padding: 3px 0;
}

.admin-data-list-status-red{
    background-color: red;
}
.admin-data-list-status-green{
    background-color: green;
}
.admin-data-list-status-yellow{
    background-color: rgb(255, 111, 0);
}

.admin-data-list-col-data{
    display: grid;
    grid-template-columns: 2fr 3fr;
    padding: 5px 15px ;
}

.admin-data-list-col-data-heading{
    font-size: 1.1rem;
    font-weight: 600;
}


.admin-data-list-col-button{
    /* background-color: red; */
    text-align: center;
    width: 100px;
    /* padding: 5px; */
    border-radius: 20px;
    margin: 20px auto 10px;
    font-size: 1.1rem;
    font-weight: 600;
}



@media only screen and (max-width: 600px) {
.admin-data-header{
    padding: 8px 30px;
}
.admin-data-heading{
    font-size: 1.3rem;
}

.admin-data-filter{
    padding: 3px 5px 3px 15px;
    font-size: 1rem;
    text-align: center;
}
.admin-data-filter span{
    display: none;
}

.admin-data-filter-col{
    right: 0;
    font-size: .9rem;
}

.admin-data-list{
    grid-template-columns: 1fr;
    /* padding: 10px 20px; */
}

.admin-data-list-col-data-heading{
    font-size: .9rem;
}

.admin-data-list-col-text{
    font-size: .9rem;
    color: rgb(216, 216, 216);
}

.admin-data-list-col-header{
    font-size: 1rem;
}

}

