.trusted-brands{
    margin: auto;
    padding: 60px 0;
    text-align: center;
}
.trusted-brands-heading{
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
    
}
.trusted-brands-heading>span{
    color: red;
}

.trusted-brands-content{
    display: flex;
    gap: 30px;
    padding: 30px 60px;
    overflow: auto;
}
.trusted-brands-content::-webkit-scrollbar{
    display: none;
}

.trusted-brands-content>img{
    height: 90px;
    width: auto;
    padding: 20px 30px;
    border-radius: 20px;
    box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
    background-color: white;
}

@media only screen and (max-width: 800px) {

.trusted-brands{
    margin: 0px auto;
    padding: 40px 0;
}

.trusted-brands-heading{
    font-size: 1.8rem;
    padding: 0 20px;
}
.trusted-brands-content{
    display: flex;
    padding: 0 20px;
    overflow: auto;
    margin: 20px auto;
}

.trusted-brands-content>img{
    height: 60px;
    padding: 15px 25px;
}

}

