.bike-brand-location{
    /* padding: 40px 0 60px; */
    margin:  auto;
    padding: 60px 0;
}

.bike-brand-location-content{
    background-color: rgb(0, 0, 0);
    box-shadow: 2px 4px 10px #fff3;
    padding: 20px 40px;
    max-width: 1300px;
    margin: auto;
}

.bike-brand-location-content>a{
    color: rgb(194, 194, 194);
    text-decoration: none;
}

.bike-brand-location-content>a:hover{
    color: red;
}


@media only screen and (max-width: 800px) {
.bike-brand-location{
    /* margin: 60px 0; */
    padding: 40px 0;
}
    .bike-brand-location-content{
        margin: 10px;
        padding: 20px;
        font-size: .9rem;
    }

}


