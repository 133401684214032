.admin-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  background-color: black;
  box-shadow: 2px 4px 10px #fff3;
  padding: 10px 50px;
  margin-bottom: 20px;
}

.admin-nav-logo{
  color: white;
  text-decoration: none;
}

.admin-nav-btn{
  /* box-shadow: 1px 1px 6px rgba(255, 255, 255, 0.595); */
  border-radius: 20px;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 5px 50px;
  background-color: red;
}

@media only screen and (max-width: 600px) {
  .admin-nav{
    padding: 10px;
  }
  .admin-nav-btn{
    font-size: .9rem;
    padding: 1px 15px;
  }
}