.register-partner{
    padding: 50px 100px 100px;
}
.register-partner-header{
    background-color: black;
    box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
    padding: 10px 30px;
    display: flex;
    font-size: 2rem;
    font-family: poppins;
    font-weight: 600;
    margin: 10px 0;
}
.register-partner-content{
    display: grid;
    gap: 10px;
    padding: 20px 30px ;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: poppins;
}
.register-partner-content span{
    font-size: .9rem;
}
.register-partner-content>form{
    width: 100%;
    display: grid;
    gap: 30px;
}
.register-partner-content>form>div{
    display: grid;
    grid-template-columns: 1fr 3fr;
    gap: 0 5px ;
}
.register-partner-content>form select,
.register-partner-content>form input{
    padding: 10px;
    font-size: 1rem;
}
.register-partner-content>form>button{
    background-color: red;
    color: white;
    border-radius: 10px;
    border: none;
    font-size: 1.3rem;
    font-weight: 600;
    font-family: poppins;
    padding: 5px 30px;
    width: 200px;
}

@media only screen and (max-width: 600px) {

    .register-partner{
        padding: 20px 0px 50px;
    }
.register-partner-header{
    font-size: 1.2rem;
    padding: 5px 10px;
}
.register-partner-content{
    padding: 0 20px;
    font-size: 1rem;
    
}
.register-partner-content span{
    font-size: .7rem;
}
.register-partner-content>form>div{
    grid-template-columns: 1fr 2fr;
}


.register-partner-content>form select,
.register-partner-content>form input{
    padding: 5px;
    font-size: .9rem;
    width: 100%;
}
.register-partner-content>form>button{
    font-size: 1rem;
    padding: 1px;
    width: 150px;
}
    
}



