.single-testimonial{
    max-width: 1000px;
    margin: auto;
    padding: 100px 0;
    
    text-align: center;
}

.single-testimonial-img>img{
    width: 400px;
    border-radius: 10px;
}

.single-testimonial-name{
    font-size: 2rem;
    font-weight: 600;
    color: #ff0000;
}

@media only screen and (max-width: 600px) {
    .single-testimonial{
        padding: 50px 20px;
        
        text-align: center;
    }
    
    .single-testimonial-img{
        padding: 20px;
    }
    .single-testimonial-img>img{
        width: 100%;
        border-radius: 10px;
    }
    
    .single-testimonial-name{
        font-size: 1.5rem;
        font-weight: 600;
        color: #ff0000;
    }
    
    
}