.package-price {
  max-width: 1300px;
  margin: auto;
  padding: 60px 0;
}

.package-price-content {
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 30px;
  position: relative;
}

.package-price-content-col-1 > div {
  padding: 10px 0;
  position: sticky;
  top: 150px;
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh; */
  /* left: 0; */
}
.package-price-content-col-1-heading {
  font-size: 2.2rem;
  font-weight: 600;
  text-align: center;
}
.package-price-content-col-1-heading > span {
  color: #ec0404;
  font-weight: 700;
}
.package-price-content-col-1-text {
  margin: 30px 10px;
  font-size: 1.1rem;
}

.package-price-content-col-1-contact {
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  gap: 20px;
}
.package-price-content-col-1-contact a {
  color: white;
  text-decoration: none;
}
.package-price-content-col-1-contact a:hover {
  text-decoration: underline;
}
.package-price-content-col-1-contact-col > i {
  color: #cf0505;
  font-size: 1.7rem;
  padding: 4px;
}
.package-price-content-col-1-contact-col:nth-child(2) i {
  font-size: 2rem;
  padding: 0;
  font-weight: bolder;
}

.package-price-content-col-1-content {
  display: grid;
  gap: 20px;
  padding: 20px 0;
}

.package-price-content-col-1-col {
  background-color: white;
  color: black;

  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 4px 4px 6px 0 #00000065, -4px -4px 6px 0 rgba(0, 0, 0, 0.6),
    inset -4px -4px 6px 0 #3f3f3fb2,
    inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.301);
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;
}
.package-price-content-col-1-col-red {
  background-color: #ec0404;
  color: white;

  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 4px 4px 6px 0 #00000065, -4px -4px 6px 0 rgba(0, 0, 0, 0.6),
    inset -4px -4px 6px 0 #3f3f3fb2,
    inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.301);
  font-weight: 600;
  font-size: 1.3rem;
  cursor: pointer;

  font-weight: 500;
  position: relative;
}
.package-price-content-col-1-col-red:after {
  border-bottom: 18px solid #0000;
  border-left: 18px solid #ec0404;
  border-top: 18px solid #0000;
  content: "";
  display: block;
  position: absolute;
  right: -15px;
  top: 14px;
  width: 0;
  z-index: -1;
}

/* ---------------------------------------------------- */

.package-price-head-category {
  display: none;
}

.package-price-content-col-2 {
  /* background-color: black;
  box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px; */
  /* padding: 20px; */
  border-radius: 20px;
}
.package-price-heading {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
}
.package-price-heading > span {
  color: #ec0404;
}

.package-price-head-text {
  width: 90%;
  color: rgb(169, 169, 169);
  margin: auto;
  text-align: center;
  margin-bottom: 30px;
}

.package-price-content-col-2-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  /* margin: 30px 0; */
}

.package-price-content-col-2-col {
  background-color: white;
  color: black;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6),
    inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
}

.package-price-content-col-2-col ul {
  padding: 10px 0 0 20px;
}

.package-price-content-col-2-col-heading-1 {
  font-size: 1.5rem;
  font-weight: 700;
  color: rgb(224, 4, 4);
}
.package-price-content-col-2-col-heading-2 {
  font-size: 1.3rem;
  font-weight: 600;
}
.package-price-content-col-2-col-heading-3 {
  font-size: 1.7rem;
  font-weight: 700;
}
.package-price-content-col-2-col-heading-3 > s {
  color: #ec0404;
  /* font-size: 1.5rem; */
  font-size: 90%;
}

.package-price-content-col-2-col-content {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
}
.package-price-content-col-2-col-button {
  text-align: center;
  background-color: rgb(226, 8, 8);
  font-size: 1.1rem;
  font-weight: 500;
  padding: 5px;
  margin-top: 10px;
  color: white;
  border-radius: 8px;
  text-decoration: none;
  box-shadow: 4px 4px 6px 0 #2d2d2d80, -4px -4px 6px 0 #50505080,
    inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 0 #0006;
  width: 160px;
  cursor: pointer;
}

/* ----------------------------------------------------------------------------------------------------------------- */

.package-price-tc {
  background-color: rgba(0, 0, 0, 0.459);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.package-price-tc-content {
  background-color: white;
  color: black;
}

.package-price-tc-heading {
  background-color: #ffdddd;
  padding: 10px 20px;
  font-size: 1.3rem;
  font-weight: 600;
}
.package-price-tc-points {
  padding: 10px 20px 10px 40px;
  max-width: 650px;
}
.package-price-tc-points li {
  margin-top: 10px;
}

.package-price-tc-button {
  text-align: end;
  padding: 0 20px 20px;
}
.package-price-tc-button > span {
  background-color: #cf0505;
  color: white;
  padding: 5px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
}

/* ----------------------------------------------------------------------------------------------------------------- */

.package-price-popup {
  background-color: rgba(0, 0, 0, 0.89);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  overflow: auto;
  z-index: 1;
  display: flex;
}

.package-price-popup-main {
  max-width: 500px;
  margin: auto;
  background-color: white;
  color: black;
}

.package-price-popup-head {
  background-color: rgb(255, 230, 230);
  display: flex;
  padding: 20px;
  justify-content: space-between;
  gap: 50px;
}

.package-price-popup-head-heading-1 {
  font-size: 1.4rem;
  font-weight: 700;
}

.package-price-popup-head-heading-2 {
  font-size: 1.2rem;
  font-weight: 600;
  color: #cf0505;
}

.package-price-popup-head-text {
  font-size: 0.8rem;
  max-width: 350px;
}
.package-price-popup-head-col > i {
  font-size: 1.7rem;
}

/* ------------------------------- */
.package-price-popup-content {
  padding: 20px;
}

.package-price-popup-content-text-1 {
  font-size: 0.95rem;
}

.package-price-popup-content-heading {
  font-size: 1.5rem;
  font-weight: 600;
  /* margin-top: 10px; */
}
.package-price-popup-content-points > ul {
  /* padding: 5px 0px 10px 15px; */
  display: grid;
  /* grid-template-columns: 7fr 5fr; */
  font-size: 0.9rem;
  gap: 5px;
}
.package-price-popup-content-points > ul > li {
  margin-left: 20px;
  /* padding-bottom: 10px; */
}

.package-price-popup-content-text-2 {
  margin-top: 10px;
  font-size: 0.7rem;
}

.package-price-popup-content-tc {
  margin-top: 10px;
  /* font-size: 1.1rem; */
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
}
.package-price-popup-content-tc > input {
  width: 20px;
  height: 20px;
}
.package-price-popup-content-tc > span {
  color: #cf0505;
  text-decoration: underline;
  cursor: pointer;
}

.package-price-popup-content-err {
  color: #ec0404;
  font-weight: 500;
  margin-top: 10px;
}

.package-price-popup-content-button {
  background-color: #cf0505;
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 1.2em;
  font-weight: 500;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 6px 0 #2d2d2d80, -4px -4px 6px 0 #50505080,
    inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 0 #0006;
  cursor: pointer;
}

.package-price-popup-content-button-disabled {
  background-color: #525252;
  color: white;
  text-align: center;
  padding: 5px;
  font-size: 1.2em;
  font-weight: 500;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 4px 4px 6px 0 #2d2d2d80, -4px -4px 6px 0 #50505080,
    inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 0 #0006;
  cursor: no-drop;
}

@media only screen and (max-width: 600px) {
  .package-price {
    /* margin: 60px auto; */
    padding: 40px 0;
  }

  .package-price-content {
    grid-template-columns: 1fr;
  }

  .package-price-content-col-1 {
    display: none;
  }
  /* ---------------------------------------------------- */

  .package-price-content-col-2 {
    padding: 0 20px;
    background-color: transparent;
  }
  .package-price-heading {
    font-size: 1.7rem;
  }
  .package-price-head-text {
    width: 100%;
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  /* ---------------------------------------------------- */

  .package-price-head-category {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: grid;
    grid-template-columns: repeat(2, 1fr); */
    gap: 0 10px;
    /* gap: 2px; */
    text-align: center;
    position: sticky;
    top: 0;
    padding: 20px;
    padding-bottom: 15px;
    margin: 0 -20px;
    background-color: black;
  }

  .package-price-head-category-col {
    background-color: white;
    color: black;

    font-size: 1.1rem;
    font-weight: 600;
    /* width: 100%; */
    box-shadow: 4px 4px 6px 0 #00000065, -4px -4px 6px 0 rgba(0, 0, 0, 0.6),
      inset -4px -4px 6px 0 #6969697e,
      inset 4px 4px 6px 2px hsla(0, 0%, 45%, 0.301);
    border-radius: 5px;
    padding: 4px 0;
  }
  .package-price-head-category-col-red {
    font-size: 1.1rem;
    font-weight: 600;
    width: 50%;
    box-shadow: 4px 4px 6px 0 #00000065, -4px -4px 6px 0 rgba(0, 0, 0, 0.6),
      inset -4px -4px 6px 0 #6969697e,
      inset 4px 4px 6px 2px hsla(0, 0%, 45%, 0.301);
    border-radius: 5px;
    padding: 4px 0;

    background-color: #ec0404;
    color: white;
  }

  .package-price-head-category > span {
    color: #ec0404;
    font-size: 3rem;
    margin: -33px;
    z-index: -1;
  }

  /* ----------------------------------------------------------- */

  .package-price-content-col-2-content {
    grid-template-columns: 1fr;
  }

  .package-price-content-col-2-col-heading-1 {
    font-size: 1.3rem;
  }
  .package-price-content-col-2-col-heading-2 {
    font-size: 1.1rem;
  }
  .package-price-content-col-2-col-heading-3 {
    font-size: 1.4rem;
  }
}
