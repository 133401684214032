.sitemap{
    padding: 50px 100px 100px;
}
.sitemap-header{
    background-color: black;
    box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
    padding: 10px 30px;
    display: flex;
    font-size: 2rem;
    font-family: poppins;
    font-weight: 600;
    margin: 10px 0;
}
.sitemap-content{
    display: grid;
    gap: 10px;
    padding: 10px 30px ;
}
.sitemap-content a{
    font-size: 1.2rem;
    display: flex;
    font-weight: 400;
    font-family: poppins;
    text-decoration: none;
    /* color: rgb(189, 189, 189); */
    color: white;
    padding: 0 10px;
}
.sitemap-content a h2{
    font-size: 1.2rem;
    font-weight: normal;
}
.sitemap-link{
    background-color: red;
    padding: 5px 0;
}


@media only screen and (max-width: 600px) {

    .sitemap{
        padding: 40px 20px 80px;
    }
.sitemap-header{
    font-size: 1.5rem;
    padding: 5px 20px;
}
.sitemap-content{
    padding: 0 20px;
}
.sitemap-content a{
    font-size: 1rem;
}
    
}



