.text-capitalize {
  display: inline;
  text-transform: capitalize !important;
}

.area-location-header {
  /* background: rgb(24, 24, 24);
  background: linear-gradient(
    45deg,
    rgba(24, 24, 24, 1) 0%,
    rgba(33, 33, 33, 1) 53%,
    rgba(75, 75, 75, 1) 100%
  );
  background: rgb(16, 16, 16);
  background: linear-gradient(
    45deg,
    rgba(16, 16, 16, 1) 0%,
    rgba(22, 22, 22, 1) 53%,
    rgba(59, 59, 59, 1) 100%
  ); */
  background: linear-gradient(
    45deg,
    rgb(0, 0, 0) 0%,
    rgba(22, 22, 22, 1) 70%,
    rgba(59, 59, 59, 1) 100%
  );
  padding: 20px 10px;
}

.area-location-header-content {
  display: grid;
  grid-template-columns: 4fr 3fr;
  width: 90%;
  margin: auto;
}

.area-location-header-col-1 {
  padding: 30px;
  /* display: grid; */
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.area-location-header-col-1-heading-1 {
  font-size: 2rem;
  font-weight: 600;
  max-width: 550px;
  line-height: 1.3;
  margin-bottom: 10px;
}
.area-location-header-col-1-heading-1 > div {
  color: red;
}
.area-location-header-col-1-heading-2 {
  font-size: 1.3rem;
  font-weight: 500;
  color: #d4d4d4;
}

.area-location-header-col-1-buttons {
  display: grid;
  gap: 30px;
}

.area-location-header-col-1-button > a,
.area-location-header-col-1-button > span {
  cursor: pointer;
  background-color: #ce0000;
  background-color: red;
  font-size: 1.2rem;
  padding: 10px 50px;
  color: white;
  border-radius: 30px;
  text-decoration: none;
  box-shadow:
    inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 0 #0006;
}

.area-location-header-col-1-increment-cols {
  width: 450px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  /* display: flex; */
  /* justify-content: center; */
  /* gap: 50px; */
}
.area-location-header-col-1-increment-col-number {
  font-size: 1.3rem;
  font-weight: 700;
}

.area-location-header-col-1-increment-col-number > span {
  color: red;
  font-size: 1.8rem;
}

/* col-2 ----------------------------------- */

.area-location-header-col-2 {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.area-location-header-col-2-content {
  background-color: white;
  box-shadow: 4px 4px 6px 0 #00000000, -4px -4px 6px 0 rgba(0, 0, 0, 0),
    inset -4px -4px 6px 0 #3f3f3fb2,
    inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.301);
  padding: 30px 20px;
  margin: 20px;
  max-width: 450px;
  color: black;
  height: auto;
  /* height: 100%; */
  /* width: 100%; */
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
  border-radius: 10px;
  /* width: 400px; */
}

.area-location-header-col-2-heading-1 {
  font-size: 1.7rem;
  font-weight: 600;
  /* margin: 10px 0; */
  line-height: 1.3;
}
.area-location-header-col-2-heading-1>div{
  color: #f30000;
  font-weight: 700;
}
.area-location-header-col-2-heading-2{
  margin-top: 10px;
}
.area-location-header-col-2-heading-2>span{
  font-weight: 600;
  font-size: 110%;
}

.area-location-header-col-2-form-heading {
  font-size: 1rem;
  /* font-weight: 500; */
  margin-bottom: 10px;
}
.area-location-header-col-2-form > form {
  display: grid;
  /* padding-top: 10px; */
  gap: 10px;
}

.area-location-header-col-2-form-div{
  display: flex;
  justify-content: space-between;
  border: solid;
  border: solid 1px rgb(227, 227, 227);
  /* border: none; */
  background-color: rgb(250, 250, 250);
  padding: 8px 10px;

}

.area-location-header-col-2-form > form > select,
.area-location-header-col-2-form > form > input {
  font-size: 1rem;
  padding: 5px 10px;
  font-family: poppins;
  /* border-radius: 5px; */
  border: solid 1px rgb(227, 227, 227);
  /* border: none; */
  background-color: rgb(250, 250, 250);
}
.area-location-header-col-2-form > form > button {
  /* margin: 20px 0 10px; */
  margin-top: 10px;
  background-color: #f30000;
  color: white;
  padding: 5px;
  border: none;
  border-radius: 7px;
  font-family: poppins;
  font-size: 1.2rem;
  font-weight: 600;
  box-shadow: 4px 4px 6px 0 #ffffff80, -4px -4px 6px 0 #747d8880,
    inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 0 #0006;
}

.area-location-header-col-2-end{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding-top: 20px;
  gap: 10px;
  margin: 0px -15px 0 -15px;
}

.area-location-header-col-2-end-icon>img{
  /* width: 40px; */
  height: 40px;
}

.area-location-header-col-2-end-text{
  font-size: .9rem;
  font-weight: 500;
}


/* general service ------------------------------- */

.area-location-g-service {
  padding: 0px;
  margin: auto;
  padding: 60px 0;
}

.area-location-g-service-heading {
  font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
}
.area-location-g-service-heading > span {
  color: red;
}

.area-location-g-service-text {
  text-align: center;
  color: rgb(169, 169, 169);
}

.area-location-g-service-col {
  max-width: 1400px;
  margin: auto;
  padding: 40px 30px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px 10px;

  /* background-color: black;
    box-shadow: rgba(255, 255, 255, 0.2) 1px 1px 20px; */
}

.area-location-g-service-col-list {
  display: flex;
  gap: 15px;
}

.area-location-g-service-col-list-icon {
  color: red;
}

/* -------------------- location price -------------------- */

.area-location-g-service-price-cols {
  max-width: 1400px;
  margin: auto;
  display: flex;
  justify-content: space-evenly;
  /* justify-content: center; */
  /* gap: 30px; */
  text-align: center;
  overflow: auto;
}

.area-location-g-service-bengaluru-price-cols{
  background: #000;
  box-shadow: 2px 4px 10px #fff3;
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  font-size: 1.4rem;
  font-weight: 600;
  height: 2.2rem;
}
.area-location-g-service-bengaluru-price-cols>div>span{
  color: red;
}
.area-location-g-service-bengaluru-price-cols>a{
  background-color: red;
  color: white;
  text-decoration: none;
  padding: 5px 30px;
  border-radius: 50px;
}
.area-location-g-service-price-list {
  background: #000;
  box-shadow: 2px 4px 10px #fff3;
  padding: 10px 30px;
  /* border-radius: 20px; */
}

.area-location-g-service-price-list-heading-1 {
  font-size: 1.3rem;
  font-weight: 600;
  color: red;
}
.area-location-g-service-price-list-heading-2 {
  font-size: 1.5rem;
  font-weight: 600;
}
.area-location-g-service-price-list-button {
  padding: 10px 0;
}
.area-location-g-service-price-list-button a {
  font-size: 1.1rem;
  font-weight: 500;
  background-color: red;
  color: black;
  color: white;
  text-decoration: none;
  padding: 3px 15px;
  border-radius: 20px;
}

/* ----------------------- our locations --------------------- */


.area-location-location-main{
  /* padding: 30px 0 30px; */
  margin:  auto;
  padding: 60px 0;
}


.area-location-our-location-main{
  /* width: 80%; */
  margin: 0 100px;
  /* margin: auto; */
  box-shadow: 2px 4px 10px #fff3;
  border-radius: 30px ;
  /* margin: 0px auto 0px; */

}

.area-location-our-location-header{
  display: flex;
  justify-content: space-between;
  font-size: 1.8rem;
  font-weight: 600;
  background-color: black;
  border-radius: 30px 30px;
  padding: 10px 20px;
}
.area-location-our-location-header>div>div{
  color: red;
}

.area-location-our-location-area{
  padding: 10px 30px;
  color: rgb(255, 255, 255);
}
.area-location-our-location-area a{
  color: rgb(175, 175, 175);
  text-decoration: none;
}
.area-location-our-location-area a:hover{
  color: red;
}



@media only screen and (max-width: 600px) {
  .area-location-header {
    padding: 30px 20px;
  }
  .area-location-header-content {
    grid-template-columns: 1fr;
    gap: 30px;
    width: 100%;
  }

  .area-location-header-col-1 {
    padding: 0px;
    gap: 30px;
  }

  .area-location-header-col-1-heading-1 {
    font-size: 1.7rem;
  }

  .area-location-header-col-1-heading-2 {
    font-size: 1.1rem;
  }
  .area-location-header-col-1-buttons {
    gap: 22px;
  }
  .area-location-header-col-1-button > a,
  .area-location-header-col-1-button > span {
    font-size: 1.1rem;
    padding: 8px 30px;
  }

  .area-location-header-col-1-increment-cols {
    width: auto;
  }

  .area-location-header-col-1-increment-col-number {
    font-size: 1.2rem;
  }
  .area-location-header-col-1-increment-col-number span {
    font-size: 1.5rem;
  }

  .area-location-header-col-1-increment-col-text{
    font-size: .9rem;
  }

  .area-location-g-service-col {
    grid-template-columns: 1fr;
  }

.area-location-header-col-2-content {
  margin: 20px auto 0;
  padding: 20px;
  width: 100%;
}
  
.area-location-header-col-2-heading-1{
  font-size: 1.4rem;
}

.area-location-header-col-2-heading-2{
  font-size: .9rem;
}

.area-location-header-col-2-form > form {
  /* padding-top: 20px; */
}

.area-location-header-col-2-end{
  /* margin: 0 -15px; */
}

.area-location-header-col-2-end-text{
  font-size: .8rem;
}

/* ------------------ check list --------------- */

.area-location-g-service{
  /* margin: 80px 0; */
  padding: 40px 0;
}

.area-location-g-service-heading{
  font-size: 1.9rem;
  /* padding: 0 0px 15px; */
  line-height: 2rem;

}

/* .area-location-g-service-col-list {
  gap: 10px;
} */

.area-location-g-service-text {
  font-size: .9rem;
  padding: 0 15px;
}

.area-location-g-service-col{
  padding: 25px 20px 0;
  font-size: .9rem;
}


/* -------------------- location price -------------------- */

.area-location-g-service-bengaluru-price-cols>div>span{
  font-size: 1.15rem;
}
.area-location-g-service-bengaluru-price-cols{
  font-size: 1.3rem;
  /* display: grid; */
  flex-direction: column;
  height: auto;
  gap: 0;
  text-align: center;
  padding: 10px 30px;
}

.area-location-g-service-bengaluru-price-cols>a{
  font-size: 1rem;
  padding: 2px 30px;
  margin-top: 10px;
}


.area-location-g-service-price-cols {
  gap: 20px;
  padding: 0 20px;
  justify-content: flex-start;
}
.area-location-g-service-price-list{
  /* min-width: 200px; */
  padding: 10px;
}



.area-location-g-service-price-list-heading-1 {
  font-size: 1.2rem;
  width: 170px;
}
.area-location-g-service-price-list-heading-2 {
  font-size: 1.3rem;
}
.area-location-g-service-price-list-button {
  padding: 10px 0;
}
.area-location-g-service-price-list-button a {
  font-size: 1rem;
}


/* ----------------- our location ------------------ */

.area-location-location-main{
  /* padding: 10px 0 20px; */
  margin:  auto;
  padding: 40px 0;
}


.area-location-our-location-main{
  /* width: 95%; */
  margin: 20px auto;
  border-radius: 0px ;

}

.area-location-our-location-header{
  font-size: 1.2rem;
  gap: 10px;
  padding: 10px;
  align-items: center;
  border-radius: 0px ;

}


.area-location-our-location-header-heading{
  flex:1;
}

.area-location-our-location-header>div>i{
  font-size: 1.5rem;
}

.area-location-our-location-area{
  font-size: .8rem;
  padding: 10px 20px;
}

}
