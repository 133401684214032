
.landing-page-nav a {
  color: white;
  text-decoration: none;
}
.landing-page-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px;
  background-color: black;
}

.landing-page-header-top {
  background-color: red;
  display: flex;
  justify-content: space-between;
  padding: 3px 110px;
}
.landing-page-header-top a {
  color: white;
  font-weight: 600;
}
.landing-page-header-top a:hover {
  font-weight: 700;
  text-decoration: none;
  color: black;
}

.landing-page-nav-logo {
  font-size: 2rem;
  font-weight: 600;
}
.landing-page-nav-logo i {
  font-size: 1.8rem;
}
.landing-page-nav-logo span {
  color: red;
}

.landing-page-nav-buttons {
  display: flex;
  gap: 20px;
}

.landing-page-nav-buttons a {
  background-color: red;
  padding: 10px 20px;
  border-radius: 10px;
  font-weight: 500;
  font-size: 1.1rem;
}
.landing-page-nav-buttons a:hover {
  transition: 0.5s;
  background-color: rgb(255, 255, 255);
  color: rgb(255, 0, 0);
  font-weight: 600;
}

.landing-page-header-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding: 40px 100px;
}

.landing-page-header-content-col-1 {
  display: grid;
  /* justify-content: center; */
  text-align: center;
  gap: 20px;
}

.landing-page-header-content-col-1-img img {
  width: 15rem;
}

.landing-page-header-content-col-1-heading {
  font-size: 2rem;
  font-weight: 600;
}

.landing-page-header-content-col-1-stars {
  display: flex;
  justify-content: center;
  gap: 5px;
  font-size: 2rem;
  color: red;
}
.landing-page-header-content-col-1-numbers {
  display: flex;
  justify-content: center;
  gap: 50px;
  color: rgb(165, 165, 165);
  font-size: 0.9rem;
  padding-top: 10px;
}

.landing-page-header-content-col-1-numbers-n {
  font-size: 1.8rem;
  font-weight: 600;
  color: white;
}

.landing-page-header-content-col-1-numbers-col-2 {
  border-left: solid;
  border-right: solid;
  padding: 0 50px;
}

.landing-page-header-content-col-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 30px;
  box-shadow: rgba(255, 255, 255, 0.44) 0px 2px 10px 0px;
  border-radius: 30px;
}

.landing-page-header-content-col-2-img img {
  width: 250px;
}
.landing-page-header-content-col-2-form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  height: 100%;
  padding: 20px 0;
}

.landing-page-header-content-col-2-form-heading img {
  display: none;
}

.landing-page-header-content-col-2-form-heading {
  font-size: 2.2rem;
  font-weight: 600;
}
.landing-page-header-content-col-2-form-heading > span > span {
  color: red;
}

.landing-page-header-content-col-2-form form {
  display: grid;
  gap: 20px;
}
.landing-page-header-content-col-2-form form input {
  background-color: transparent;
  border: solid rgb(205, 205, 205) 2px;
  padding: 7px 20px;
  color: white;
  border-radius: 10px;
  font-size: 1rem;
}

.landing-page-header-content-col-2-form form select {
  background-color: transparent;
  border: solid rgb(205, 205, 205) 2px;
  padding: 7px 20px;
  color: white;
  border-radius: 10px;
  font-size: 1rem;
}

.landing-page-header-content-col-2-form form option {
  color: black;
}
.landing-page-header-content-col-2-form form button {
  color: white;
  background-color: red;
  font-size: 1.2rem;
  padding: 8px;
  border: solid white 1px;
  border: none;
  border-radius: 10px;
  font-weight: 600;
}

.landing-page-header-content-col-2-form form button:hover {
  background-color: white;
  color: red;
  transition: 0.5s;
  /* font-weight: 700; */
}

.landing-page-header-bottom {
  background-color: red;
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  padding: 10px;
}
.landing-page-header-bottom a {
  color: white;
}
.landing-page-header-bottom a:hover {
  color: rgb(0, 0, 0);
  font-weight: 800;
  transition: 0.5s;
}

/* --------------------- price ------------------ */

.landing-page-price-check-heading span {
  color: white;
}
.landing-page-price-check-heading {
  font-size: 2rem;
  font-weight: 600;
  color: red;
  text-align: center;
  padding-top: 10px;
}

.landing-page-price-check {
  border: red solid 5px;
  border-radius: 30px;
}

.landing-page-price {
  margin: 50px 100px;
}

.landing-page-price-check-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* margin-left: 190px; */
  gap: 30px 10px;
  padding: 40px 20px 40px 50px;

  /* text-align: center; */
}

.landing-page-price-button {
  display: flex;
  justify-content: space-evenly;
}

.landing-page-price-button-col {
  text-align: center;
  background-color: red;
  padding: 5px 20px 0px 20px;
  border-radius: 0 0 20px 20px;
}

.landing-page-price-button-col-heading-1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.landing-page-price-button-col-heading-2 {
  font-size: 1.6rem;
  color: rgb(220, 220, 220);
  margin-bottom: 8px;
}

.landing-page-price-button-col-button {
  background-color: white;
  color: red;
  font-weight: 600;
  text-decoration: none;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 1.1rem;
  border: solid 4px;
}

.landing-page-price-button-col-button:hover {
  transition: 0.6s;
  background-color: red;
  border: rgb(255, 255, 255) solid 4px;
  color: white;
}

/* -------------- footer ---------- */

.landing-page-footer {
  background-color: black;
}
.landing-page-footer-content {
  padding: 40px 200px 60px 100px;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  gap: 10px;
}

.landing-page-footer-col-1 {
  text-align: center;
}

.landing-page-footer-col-1-logo span {
  color: red;
}

.landing-page-footer-col-1-logo i {
  font-size: 1.8rem;
}
.landing-page-footer-col-1-logo a {
  font-size: 2rem;
  font-weight: 600;
  color: white;
  text-decoration: none;
}

.landing-page-footer-col-1-tagline {
  font-size: 1.1rem;
  letter-spacing: 4px;
  word-spacing: 2px;
}

.landing-page-footer-col-2 {
  display: flex;
  justify-content: space-between;
}

.landing-page-footer-col-2-list-heading {
  font-size: 1.5rem;
  font-family: "nunito";
  color: red;
  font-weight: 800;
}

.landing-page-footer-col-2-list-points {
  display: grid;
  height: 100%;
}

.landing-page-footer-col-2-list-points span {
  color: red;
}
.landing-page-footer-col-2-list-points a {
  color: rgb(170, 170, 170);
  text-decoration: none;
}

.landing-page-footer-col-2-list-points a:hover {
  color: white;
}

/* ---------- location ---------- */

.landing-page-location {
  margin: 0px 0;
}

.landing-page-location-heading {
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
}
.landing-page-location-heading span {
  color: red;
}

.landing-page-location-locations {
  display: flex;
  text-align: center;
  gap: 50px;
  justify-content: center;
  justify-content: flex-start;
  padding: 20px 80px 20px 60px;
  overflow: auto;
}
.landing-page-location-locations::-webkit-scrollbar {
  display: none;
}


/* .landing-page-location-locations::-webkit-scrollbar {
  width: 20px;
}

.landing-page-location-locations::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  box-shadow: none; 
  border-radius: 10px;
  width: 30px;
}
 
.landing-page-location-locations::-webkit-scrollbar-thumb {
  background: red; 
  border-radius: 10px;
  width: 20px;
}

.landing-page-location-locations::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
} */

.landing-page-location-locations-col {
  text-align: center;
  padding: 20px;
  background-color: black;
  color: white;
  box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
  
  /* background-color: white;
  color: #000;
  box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5); */
  border-radius: 20px;
  text-decoration: none;
  min-width: 170px;
  font-weight: 700;
  /* min-width: auto; */
}

.landing-page-location-locations-col-icon img {
  border-radius: 50%;
  width: 100px;
}

.landing-page-location-locations-col-text {
  font-size: 1.1rem;
  font-weight: 600;
}
.landing-page-location-locations-col-text span {
  color: red;
  /* font-weight: 500; */
}

@media only screen and (max-width: 800px) {
  .landing-page-header-top {
    padding: 5px 5px;
    font-size: 0.8rem;
  }

  .landing-page-nav {
    padding: 10px 15px;
  }

  .landing-page-nav-logo i {
    font-size: 1.3rem;
  }
  .landing-page-nav-logo {
    font-size: 1.3rem;
  }

  .landing-page-nav-buttons {
    gap: 10px;
  }
  .landing-page-nav-buttons a {
    background-color: red;
    padding: 3px 7px;
    border-radius: 10px;
    font-weight: 500;
    font-size: 0.8rem;
  }

  .landing-page-header-content {
    grid-template-columns: 1fr;
    padding: 20px;
    gap: 30px;
  }

  .landing-page-header-content-col-1 {
    gap: 10px;
  }

  .landing-page-header-content-col-1-heading {
    font-size: 1.4rem;
    font-weight: 600;
  }
  .landing-page-header-content-col-1-stars {
    font-size: 1.7rem;
  }

  .landing-page-header-content-col-1-numbers {
    gap: 15px;
    font-size: 0.8rem;
  }
  .landing-page-header-content-col-1-numbers-col-2 {
    padding: 0 15px;
  }

  .landing-page-header-content-col-1-numbers-n {
    font-size: 1.3rem;
  }

  .landing-page-header-content-col-2-form {
    padding: 20px 10px;
  }

  .landing-page-header-content-col-2-form-heading {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    /* padding:  0 10px; */
  }

  .landing-page-header-content-col-1-img img {
    width: 12rem;
  }

  .landing-page-header-content-col-2-form-heading img {
    display: block;
    width: 80px;
    margin-right: 20px;
  }

  .landing-page-header-content-col-2-form form {
    padding: 20px 10px;
    gap: 20px;
  }

  .landing-page-header-content-col-2-img {
    display: none;
  }

  .landing-page-header-bottom {
    font-size: 1.1rem;
    padding: 10px;
  }

  /* --------- price --------- */

  .landing-page-price {
    margin: 20px 10px;
  }

  .landing-page-price-check-heading {
    font-size: 1.5rem;
    padding: 10px 40px;
  }

  .landing-page-price-check-list {
    padding: 10px 0 20px 20px;
    /* padding-left: 20px; */
    font-size: 0.8rem;
    gap: 15px;
    grid-template-columns: 1fr;
  }

  .landing-page-price-button {
    display: flex;
    overflow: auto;
    overflow-y: hidden;
    gap: 20px;
    justify-content: space-between;
    padding-bottom: 10px;
    /* background-color: gray; */
  }

  .landing-page-price-button-col-heading-1 {
    width: 190px;
    font-size: 1.3rem;
  }
  .landing-page-price-button-col-heading-2 {
    font-size: 1.3rem;
  }

  .landing-page-price-button {
    margin: 0 30px;
  }

  /* -------------- footer ---------- */

  .landing-page-footer-content {
    grid-template-columns: 1fr;
    padding: 30px 10px 80px;
    gap: 50px;
  }

  .landing-page-footer-col-2-list-heading {
    font-size: 1.3rem;
  }

  .landing-page-footer-col-2 {
    gap: 20px 0;
    flex-wrap: wrap;
    gap: 30px 10px;
    justify-content: space-around;
  }
  .landing-page-footer-col-2-list-points {
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* gap: 0px; */
    font-size: 1rem;
  }

  .landing-page-footer-col-2-list-points-contact {
    height: 130px;
  }

  .landing-page-footer-col-1-logo a {
    font-size: 1.6rem;
  }

  .landing-page-footer-col-1-logo i {
    font-size: 1.5rem;
  }

  .landing-page-footer-col-1-tagline {
    font-size: 1rem;
    letter-spacing: 2px;
    word-spacing: 1px;
  }

  /* ---------- location ---------- */

  .landing-page-location {
    margin: 20px 0;
  }
  .landing-page-location-heading {
    font-size: 1.8rem;
    margin: 0 20px;
  }
  .landing-page-location-locations {
    overflow: auto;
    gap: 20px;
    justify-content: start;
    padding: 10px;
  }

  .landing-page-location-locations-col {
    padding: 10px;
    min-width: auto;
  }

  .landing-page-location-locations-col-1 {
    margin-left: 20px;
  }
  .landing-page-location-locations-col-icon img {
    width: 80px;
  }

  .landing-page-location-locations-col-text {
    font-size: 0.9rem;
    width: 115px;
  }
}

@media only screen and (max-width: 400px) {
  .landing-page-nav-logo i {
    font-size: 1rem;
  }
  .landing-page-nav-logo {
    font-size: 1.1rem;
  }

  .landing-page-nav-buttons {
    gap: 5px;
  }
  .landing-page-nav-buttons a {
    font-size: 0.7rem;
  }
}
