.landing-page{
  position: relative;
}
.landing-sticky-nav{
  position: sticky;
  top: 0;
  z-index: 1;
}

.landing-nav-top {
  background-color: #d30000;
  color: #fff;
  display: flex;
  gap: 5px;
  justify-content: center;
  font-weight: 500;
  padding: 10px;
}

.landing-nav-top > span {
  color: rgb(0, 0, 0);
  font-weight: 600;
  animation: blink 2.5s linear infinite;
  
}


/* navbar ----------------------------------------------------------- */

.landing-nav{
  background-color: #000000;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  height: 70px;
  border-bottom: #1b1b1b solid 1px;
}

.landing-nav-main {
  display: flex;
  padding: 0px 10px;
  /* align-items: center; */
  gap: 50px;
  max-width: 1300px;
  margin: auto;
  justify-content: space-between;
}

.landing-nav-logo-image{
  background-color: #fff;
  border-radius: 0 0 30px 30px;
}


.landing-nav-logo-image > img {
  width: 160px;
  padding: 15px 20px 15px;
}

.landing-nav-btn {
  display: none;
}

.landing-nav-main a {
  text-decoration: none;
  color: #ffffff;
}

.landing-nav-menu {
  display: flex;
  gap: 40px;
  padding-top: 22px;
}
/*  ----------------------------------------------------------- */
/* header ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */

.landing-header-content{
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 1200px;
  margin: auto;
  padding: 20px 0;
}


.landing-page 
.area-location-header-col-2-content{
  animation: shake .8s ease-in-out ;
}


.landing-header-form-animate{
  animation: shake .8s ease-in-out ;
}
/*  ----------------------------------------------------------- */
/* footer ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */

.landing-footer{
  background-color: #000;
}

.landing-footer-main{
  padding: 50px 20px 30px;
  max-width: 1300px ;
  margin: auto;
  display: grid;
  grid-template-columns: 2fr 3fr;
}

.landing-footer-col-1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;  
}

.landing-footer-logo>img{
  width: 220px;
  background-color: #fff;
  padding: 15px 30px;
  box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
  border-radius: 20px;
}

.landing-footer-icons{
  display: flex;
  align-items: center;
  gap: 20px;
}

.landing-footer-icons>div{
  color: #ffffff;
  border: 1px solid;
  width:  40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
}

.landing-footer-col-2{
  display: flex;
  justify-content: space-evenly;
  gap: 20px;
  flex-wrap: wrap;
}

.landing-footer-cc{
  text-align: center;
  padding: 15px;
  border-top: 1px solid #1d1d1d;
  color: #afafaf;
}

.landing-footer-col-2-heading{
  font-size: 1.3rem;
  font-weight: 600;
  color: #f00;
}
.landing-footer-col-2-points{
  color: #afafaf;
  display: grid;
  gap: 10px;
  margin-top: 10px;
}
.landing-footer-col-2-points div,
.landing-footer-col-2-points a{
  color: #afafaf;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 15px;
}
.landing-footer-col-2-points a:hover{
  cursor: pointer;
  color: #fff;
  text-decoration: underline;
}


/*  ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */

.landing-page-button{
  width: max-content;
  margin: auto;
  margin-top: 30px;
}
.landing-page-button a{
  background-color: #ff0000;
  color: #fff;
  box-shadow: inset -4px -4px 6px 0 #fff3, inset 4px 4px 6px 0 #0006;
  border-radius: 30px;
  text-decoration: none;
  padding: 10px 40px;
  font-size: 1.2rem;
  font-weight: 500;
}


/*  ----------------------------------------------------------- */

@media only screen and (max-width: 1000px) {

  
  .landing-nav-top {
    font-size: .8rem;
    flex-wrap: wrap;
  }

  /* navbar ----------------------------------------------------------- */
  
  .landing-nav{
    height: 50px;
  }
    
    
  .landing-nav-main {
    justify-content: space-between;
  }

  .landing-nav-menu {
    /* padding: 0; */
    position: fixed;    
    top: 100vh;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    background-color: #1d1d1d;
    transition: 0.4s;
  }

  .landing-nav-open{
    transform: translateY(-100vh);
    z-index: 2;
  }

  
  .landing-nav-btn {
      display: block;
      font-size: 1.2rem;
      padding-top: 10px;
    }
    .landing-nav-close-btn{
        color: #f00;
        font-size: 2rem;

    }

    .landing-nav-logo-image > img {
      width: 120px;
      padding: 5px 10px 5px;
  }


  
/*  ----------------------------------------------------------- */
/* header ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */

.landing-header-content{
  display: flex;
  flex-direction: column-reverse;
  gap: 50px;
  padding: 0px 0;
}
  
/*  ----------------------------------------------------------- */
/* footer ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */


.landing-footer-main{
  grid-template-columns: 1fr;
}
.landing-footer-col-2{
  justify-content: space-between;
  padding: 40px 20px 0px;
}

/*  ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */

.landing-page-button a{
  font-size: 1.2rem;
  padding: 12px 40px;
}

/*  ----------------------------------------------------------- */
/*  ----------------------------------------------------------- */

    
}
