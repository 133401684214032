

/* ------------------- home-container-6 --------------- */

.home-container-6 {
    text-align: center;
    /* padding: 40px 0; */
    /* max-width: 1400px; */
    margin: auto;
    padding: 60px 0;
    font-family: "poppins";
  }
  
  .home-container-6-heading {
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
      
    }
    .home-container-6-heading>span {
  color: red;
  font-weight: 700;
    }
  .home-container-6-head-col-1-heading-1 {
    font-size: 18px;
    font-size: 1.4rem;
    font-weight: 600;
    margin-top: 15px;
  }
  
  .home-container-6-head {
    display: flex;
    flex-direction: column;
  }
  
  .home-container-6-head-col-1-heading-2 {
    font-size: 1.1rem;
  }
  .home-container-6-head-col-1-heading-2 > i {
    color: #f9cc18;
  }
  
  .home-container-6-head-col-2 {
    padding: 20px;
  }
  
  .home-container-6-head-col-2 > a {
    text-decoration: none;
    background-color: #ff0000;
  box-shadow: 4px 4px 6px 0 #00000080, -4px -4px 6px 0 #00000080, inset -4px -4px 6px 0 rgba(51, 51, 51, 0.226), inset 4px 4px 8px 0 rgba(46, 22, 22, 0.719);

    color: white;
    font-size: 17px;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 10px;
  }
  
  .home-container-6-content-cols {
    display: flex;
    overflow: scroll;
    gap: 40px;
    padding: 30px 60px;
  }
  .home-container-6-content-cols::-webkit-scrollbar {
    display: none;
  }
  
  .home-container-6-content-col-1 {
    min-width: 380px;
    background-color: white;
    color: black;
    box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
    border-radius: 20px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .home-container-6-content-cos-1-google img {
    width: 30px;
  }
  
  .home-container-6-content-cos-1-stars{
    color: #f6c800;
    display: flex;
    justify-content: center;
    gap: 12px;
    font-size: 2rem;
    padding: 5px;
  }
  
  .home-container-6-content-cos-1-name {
    font-size: 18px;
    font-weight: 600;
  }
  
  .home-container-6-content-cos-1-date {
    color: gray;
  }
  
@media only screen and (max-width: 800px) {

    
  /* ------------------- home-container-6 --------------- */

  .home-container-6{
    margin:  auto;
    padding: 40px 0;
  }

  .home-container-6-heading {
    font-size: 2rem;
  }

  .home-container-6-head-col-1-heading-1 {
    font-size: 18px;
    font-weight: 600;
  }


  .home-container-6-head-col-2 > a {
    font-size: 17px;
    padding: 8px 15px;
  }

  .home-container-6-content-cols {
    gap: 20px;
    padding: 15px 20px;
  }

  .home-container-6-content-col-1 {
    min-width: 300px;
    border-radius: 20px;
    padding: 20px;
  }


  .home-container-6-content-cos-1-stars {
    font-size: 25px;
    gap: 10px;
  }


    
}