.dn{
  /* display: none */
}

.bg-black{
  background-color: black
}


/* .header-main{
  position: relative;
}

.header-logo{
  position: absolute;
  top: 0px;
  left: 20px;
  background-color: white;
  padding: 15px 15px 15px;
  border-radius: 0 0 40px 40px  ;
}
.header-logo>img{
  width: 150px;
} */


/* @import url("https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,900&display=swap"); */


@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Pacifico&family=Playpen+Sans:wght@100;200;300;400;500;600;700;800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Serif:ital,opsz,wght@0,8..144,100;0,8..144,200;0,8..144,300;0,8..144,400;0,8..144,500;0,8..144,600;0,8..144,700;0,8..144,800;0,8..144,900;1,8..144,100;1,8..144,200;1,8..144,300;1,8..144,400;1,8..144,500;1,8..144,600;1,8..144,800;1,8..144,900&family=Stylish&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: #000000;
  color: white;
  /* font-family: Arial, Helvetica, sans-serif; */
  font-family: poppins;
}


.web-app{
  max-width: 2000px;
  margin: auto;
  background: #111111;
  box-shadow: rgba(134, 134, 134, 0.12) 0px 2px 4px 0px, rgba(126, 126, 126, 0.32) 0px 2px 16px 0px;
  
}

.heading-underline>hr{
  color: red;
  border: dashed 3px;
  border-radius: 50%;
  max-width: 300px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  
  .heading-underline>hr{
    color: red;
    border: solid 3px;
    border-radius: 50%;
    max-width: 250px;
    margin: auto;
  }
  
  
}

select{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

button {
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.loading-component{
  position: fixed;
  z-index: 8;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  background-color: rgba(0, 0, 0, 0.792);
  /* color: white; */
  font-weight: 700;
  /* color: #14171a; */
}

.home-pg-padding{
  padding: 0 20px 80px;
}


/* ------------- header start ---------------- */

.header {
  padding: 20px 100px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  align-items: center;
  background-color: black;
  /* background-image: url(./images/bike-service-at-home.jpg); */
  /* background-image: url(./images/bike-service-at-home.webp); */
  background-image: url(./images/bike-service-at-home-1.webp);
  background-size: cover;
  /* background-size: 100% 100%; */
}
.header-col-2 {
  width: 90%;
}
.header-col-2 img {
}

.head-img-1 {
  width: 90%;
  position: relative;
}
.head-img-2 {
  position: absolute;
  /* width: 100px; */
  top: 80px;
  left: 52%;
  width: 520px;
  height: 520px;
}
.head-img-4 {
  border-radius: 50px 0 50px 50px;
  width: 105%;
}

.header-col-1 {
  text-align: center;
  font-family: poppins;
  /* width: 40%; */
  padding: 30px;
  /* background-color: rgb(0, 0, 0);
  border-radius: 0 50px 50px;
  box-shadow: rgba(255, 255, 255, 0.2) 0px 2px 8px 0px;  */
}

.header-c-heading-1 {
  font-size: 2rem;
  font-weight: 600;
}
.header-c-heading-2 {
  font-size: 1.2rem;
  font-weight: 500;
  color: rgb(214, 214, 214);
  padding-bottom: 30px;
}

.header-c-inc {
  /* display: flex;
  justify-content: center; */
  /* gap: 60px; */
  width: 80%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: end;
}

.header-c-inc-col {
  font-size: 1rem;
}
.header-c-inc-col h4{
  font-size: 1rem;
  font-weight: normal;
}
.header-c-inc-col span {
  font-size: 1.8rem;
  font-weight: 600;
  color: red;
}

.header-c-form > input {
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.header-c-form > button {
  font-size: 1.4rem;
  padding: 10px 40px;
  font-weight: 600;
  border-radius: 10px;
  background-color: rgb(232, 0, 0);
  color: white;
  margin-bottom: 20px;
  border: none;
  box-shadow: 4px 4px 6px 0 #00000080, -4px -4px 6px 0 #00000080, inset -4px -4px 6px 0 rgba(51, 51, 51, 0.226), inset 4px 4px 8px 0 rgba(46, 22, 22, 0.719);

}
@media only screen and (max-width: 600px) {
  
.home-pg-padding{
  padding: 0 0px 20px;
}

  
  .header {
    grid-template-columns: 1fr;
    padding: 40px 20px ;
    background-image: none;
  }
  .header-col-2 {
    display: none;
  }

  .header-col-1 {
    padding: 0;
  }
  .header-c-heading-1 {
    font-size: 1.5rem;
  }

  .header-c-heading-2 {
    font-size: 1rem;
    padding-bottom: 20px;
  }

  .header-c-form > input {
    font-size: 1rem;
    padding: 8px 15px;
    border-radius: 10px;
    margin-bottom: 10px;
  }
  .header-c-form > button {
    
    font-size: 1.1rem;
    padding: 8px 40px;
    font-weight: 600;
    border-radius: 25px;
    margin-bottom: 20px;
  }

  

.header-c-inc{
  width: 100%;
}

  .header-c-inc-col {
    font-size: 0.8rem;
  }
  .header-c-inc-col h4{
    /* font-size: 0.8rem; */
  }
  .header-c-inc-col span {
    font-size: 1.5rem;
  }
  .header-c-inc {
    /* gap: 30px; */
  }
}
/* ------------- header end ---------------- */

/* ---------- ourServiceComponent start ---------- */

.our-service {
  font-family: poppins;
  text-align: center;
}
.our-service-c-heding-1 {
  font-size: 3rem;
  font-weight: 600;
  color: red;
  line-height: 50px;
}

.our-service-content {
  margin: 40px 0;
  /* display: flex; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* color: red; */
  align-items: center;
  /* justify-content: space-evenly; */
}

.our-s-col-1 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 30px 0;
  text-align: end;
}

.our-s-col-2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  padding: 30px 0;
  text-align: start;
}

.our-s-img {
  padding: 0 20px;
}

.ourService-img {
  /* text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF; */
  /* text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF; */
  /* text-shadow: 0 0 3px #FF0000, 0 0 5px #0000FF; */
  /* filter: border(solid); */
  filter: drop-shadow(0 0px 0.3rem rgba(255, 0, 0, 0.418));


}

.our-s-c-heading {
  font-size: 2rem;
  font-weight: 500;
  color: red;
  margin-bottom: 20px;
}

.our-s-c-points {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 1.2rem;
}

.our-s-c-points > div:nth-child(1) {
  margin-right: -10px;
}

.our-s-c-points > div:nth-child(5) {
  margin-right: -30px;
}

.our-s-c-points > div:nth-child(2),
.our-s-c-points > div:nth-child(4) {
  margin-right: 30px;
}

.our-s-c-points > div:nth-child(3) {
  margin-right: 50px;
}

.our-s-l-points {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  font-size: 1.2rem;
}

.our-s-l-points span {
  color: red;
}

.our-s-l-points > div:nth-child(2),
.our-s-l-points > div:nth-child(5) {
  margin-left: 30px;
}

.our-s-l-points > div:nth-child(3) {
  margin-left: 60px;
}
.our-s-l-points > div:nth-child(4) {
  margin-left: 60px;
}

.our-service-c-heding-2 {
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  color: rgb(156, 156, 156);
}

.our-s-img-1 {
  position: relative;
  top: 20px;
  margin-left: -30px;
}
.our-s-img-2 {
  position: absolute;
  right: 33%;
  width: 35%;
}

.our-service img {
  /* padding: ; */
  width: 100%;
  margin: auto;
  padding: 0 0 0 20px;
}


/* -------------------- our serv ---------------------- */
.our-serv{
  margin: 100px auto;
}
.our-serv-header{
  text-align: center;
}
.our-serv-header-heading{
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
}
.our-serv-header-heading>span{
  color: #ff0000;
}
.our-serv-header-text{
  
  font-size: 1.3rem;
  font-weight: 500;
  color: rgb(156, 156, 156);
}

.our-serv-content{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  /* border: solid; */
}

.our-serv-content-col-1{
  text-align: end;
}
.our-serv-content-col-heading{
  font-size: 1.5rem;
  font-weight: 600;
  color: rgb(255, 31, 31);
  margin-bottom: 10px;
}
.our-serv-content-col-list{
  /* border: solid; */
  font-size: 1.2rem;
  height: 300px;
  display: grid;
}
.our-serv-content-img img{
  width: 80%;
}

@media only screen and (max-width: 600px) {
/* -------------------- our serv ---------------------- */

.our-serv{
  margin: 50px 20px;
}

.our-serv-header-heading{
  font-size: 1.8rem;
}

.our-serv-header-text{
  font-size: 1rem;
}
.our-serv-content-col-1{
  text-align: start;
}
.our-serv-content{
  display: grid;
  grid-template-columns: 1fr;
  margin: 10px 0;
}

.our-serv-content-col-list{
  height: auto;
  font-size: .9rem;
}
.our-serv-content-col-list-point{
  margin: 0 10px ;
}
.our-serv-content-col-list-point::before{
  content: "\25c8";
  color: red;
  font-size: 1.2rem;
  margin-right: 10px;
}

.our-serv-content-col-heading{
  font-size: 1.4rem;
  margin-bottom: 0px;
}

/* ------------------------------ */
  
  .our-service-content {
    display: grid;
    grid-template-columns: 1fr;
    margin: 0px 0 20px;
    /* padding: 0 20px; */
  }
  
  .our-service-c-heding-1 {
    font-size: 1.8rem;
  }
  .our-service-c-heding-2 {
    font-size: 1rem;
  }

  .our-s-col-2,
  .our-s-col-1 {
    padding: 0 20px;
  }

  .our-s-col-1 {
    text-align: start;
  }

  .our-s-c-heading {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
  .our-s-c-points > div,
  .our-s-l-points > div {
    margin: 0px 10px !important;
    font-size: 0.8rem;
  }
  .our-s-l-points > div::before,
  .our-s-c-points > div::before {
    content: "\25c8";
    color: red;
    font-size: 1.2rem;
    margin-right: 10px;
  }

  .our-s-col-1 {
    padding: 10px;
  }

  .our-s-img {
    order: -1;
    width: 100%;
  }
  .our-s-img img {
    width: 90%;
  }

  .our-s-img-1 {
    position: relative;
    margin-left: 9px;
  }
  .our-s-img-2 {
    position: absolute;
    right: 3%;
    /* width: 35%; */
  }

  .our-service img {
    /* padding: ; */
    width: 80%;
    margin: auto;
    padding: 0px 0;
  }
}

/* ---------- ourServiceComponent end ---------- */

/* ------------- Offer start ---------------- */

.offer-main {
  max-width: 1330px;
  margin: auto;
  padding: 50px 50px;
  text-align: center;
}

.offer-main-heading-1{
  font-size: 2.8rem;
  font-weight: bold;
  text-align: center;
  font-family: stylish;
}
.offer-main-heading-1>span{
  color: #ff0000;
}
.offer-main-heading-2{
  margin-bottom: 10px;
  color: rgb(169, 169, 169)
}
.offer-heading {
  text-align: center;
  color: red;
  font-size: 2.5rem;
  font-weight: 700;
}
.offer-slider {
  /* width: 100%; */
}

.offer-img {
  padding: 10px;
}
.offer-img img {
  width: 100%;
  border-radius: 20px;
  cursor: pointer;
}

.slick-dots li button:before {
  color: white;
  font-size: small;
}

.slick-dots li.slick-active button:before {
  color: red;
}

@media only screen and (max-width: 600px) {
  .offer-main{
    margin: 20px 0 50px;
    padding: 10px 28px;
  }
.offer-main-heading-1{
  font-size: 1.9rem;
}
.offer-main-heading-2{
  font-size: .9rem;
}
  .offer-img {
    padding: 5px;
  }
}

/* 
.slick-dots li button:before {
  color: white;
  font-size: small;
}

.slick-dots li.slick-active button:before {
  color: red;
}
.slick-next:before,
.slick-prev:before {
}

.offer-heading {
  font-size: xx-large;
  padding-bottom: 7px;
  text-align: center;
  font-weight: bold;
}
.offer-slider {
  width: 95%;
  margin: auto;
}
.offer-img {
  padding: 0 12px;
}

.offer-img img {
  border-radius: 20px;
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .slick-dots li button:before {
    color: white;
    margin-top: -10px;
    font-size: xx-small;
  }

  .slick-dots li.slick-active button:before {
    color: red;
  }

  .offer-heading {
    font-size: x-large;
    padding-bottom: 5px;
    text-align: center;
    font-weight: bold;
  }
  .offer-img {
    padding: 0 5px;
  }
  .offer-slider {
    width: 85%;
    margin: auto;
  }
} */
/* ------------- Offer end ---------------- */

/* ----------PopUp start ---------- */
.offer-popup {
  position: fixed;
  top: 0%;
  /* left: 20px;  */
  z-index: 2;
  width: 100%;
  margin: auto;
  height: 100vh;
  background-color: rgba(29, 29, 29, 0.962);
  animation: fadeInOpacity 1s;
  /* box-shadow: rgb(255, 255, 255) 0px 2px 8px 0px; */
  box-shadow: rgba(251, 251, 252, 0.499) 0px 7px 29px 0px;

  display: flex;
  justify-content: center;
  align-items: center;
}
.offer-section {
  /* color:black; */
  /* background-color: #ffd7d7; */
  /* border-radius: 20px; */
  background-color: #e82434;
  background-image: url(../src/images/bike-service-near-me.jpg);
  
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 35%;
  height: 60vh;
  
  box-shadow: rgba(251, 251, 252, 0.373) 0px 7px 29px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  padding-bottom: 30px;
  color: white;
}
.offer-popup-main {
  display: flex;
  /* background-color: #414141; */
  flex: 1;
  justify-content: center;
  align-items: center;
}
.offer-content {
}
.offer-text-1 {
  font-size: xx-large;
}
.offer-text-2 {
  line-height: 50px;
  font-size: 4rem;
  font-weight: bold;
}
.offer-text-3 {
  padding: 5px 0 10px;
  font-size: 1.3rem;
}
.offer-text-4 {
  font-size: 1.3rem;
}
.offer-closeBtn {
  padding: 20px 20px 0;
  width: 100%;
  text-align: end;
  background: none;
  border: none;
}
.offer-icon {
  color: white;
  font-size: xx-large;
  font-weight: bold;
}

.offer-claim div{
  
}

.offer-claim {
  color: #ffffff;
  font-weight: bold;
  font-size: x-large;
  font-size: 1.4rem;
  padding: 8px 35px;
  background-color: rgb(255, 0, 0);
  text-decoration: none;
  border-radius: 50px;
  border: solid 2px;
}

/* offer-2 */

.offer-section {
  /* width: 50%; */
}

.offer-content-2 {
  padding: 10px 30px;
  display: grid;
  grid-template-columns: 35% 65%;
  justify-content: space-evenly;
  align-items: center;
}
.offer-txt {
  text-align: end;
}

.offer-txt-1 {
  font-size: xxx-large;
  font-weight: bold;
}
.offer-txt-2 {
  font-size: 1.2rem;
  padding-bottom: 15px;
}
.offer-txt-0 {
  font-size: 1.5rem;
}

.offer-img {
  /* width: ; */
}

.off-sli {
  width: 100%;
}
.offer-content input {
  font-size: 1.1rem;
  padding: 7px 15px;
  border-radius: 10px;
  border: none;
  margin-bottom: 10px;
  background-color: rgb(228, 228, 228);
  color: red;
}
@media only screen and (max-width: 600px) {
  .offer-section {
    width: 90%;
    height: 400px;
    /* height: 520px; */
  }
  .offer-content-2 {
    grid-template-columns: repeat(1, 1fr);
  }
  .offer-txt {
    text-align: center;
  }
  .offer-img {
    order: -1;
  }
  .offer-popup-main {
    padding: 20px;
  }
  .offer-text-1 {
    font-size: 1.5rem;
  }
  .offer-text-2 {
    font-size: 2.8rem;
  }
  .offer-text-3 {
    font-size: 1.2rem;
  }
  .offer-content input {
    font-size: 1.2rem;
    /* padding: 10px ;  */
  }
  .offer-claim {
    font-size: 1.2rem;
  }
}

/* ----------PopUp end ---------- */

/* ----------------- location -------------- */
.closeLoc {
  position: fixed;
  font-size: 2rem;
  top: 10px;
  right: 10px;
}
.location-col {
  padding: 80px;
  background-color: #9f1616;
  position: fixed;
  top: 0px;
  right: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.location-col-h1 {
  font-size: 2.1rem;
  font-weight: 600;
  text-align: center;
}
.select-btn {
  margin-top: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  text-align: center;
  width: 100%;
}
.select-btn>div {
  /* width: 100%;
  height: 100%; */
  display: grid;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.select-btn div img {
  border-radius: 50%;
  width: 100px;
}
.select-btn>div>span{
  /* width: 100%;
  background-color: #cd5e5e ; */
}
.select-btn>div>span>div>div{
  height: 100px;
  width: 100px;
  border-radius: 100%;
  display: flex;
  font-size: 3.4rem;
  justify-content: center;
  align-items: center;
  background-color: white;
  color: red;
  margin-bottom: 5px;
}

.select-locatin {
  margin-top: 20px;
  text-align: center;
  font-size: 1.4rem;
  padding: 10px;
  font-weight: 600;

  border: solid;
}

@media only screen and (max-width: 600px) {
  .location-col {
    padding: 20px;
    height: 100%;
    width: 100%;
    z-index: 3;
    /* display: flex !important; */
  }
  .select-btn div img {
    width: 60px;
  }

.select-btn>div>span>div>div{
  width: 60px;
  height: 60px;
  font-size: 2.5rem;
}

  .select-btn {
    gap: 10px;
  }

  .select-btn div {
    font-size: 0.8rem;
  }

  .select-locatin {
    margin-top: 20px;
    font-size: 1.1rem;
    padding: 4px;
  }
  .location-col-h1 {
    font-size: 1.8rem;
  }
}

/* ----------------- term and condition --------------- */

.termConditionMain {
  padding: 20px 100px;
}

.TC-heading-1 {
  font-size: 2.8rem;
  /* margin-left: 200px; */
  text-align: center;
  font-weight: 600;
  margin-bottom: 30px;
}
.TC-heading-1 span {
  color: red;
}

.TC-heading-2 {
  font-size: 1.3rem;
  font-weight: 600;
  color: red;
  margin-top: 30px;
}
.TC-text {
  margin-left: 20px;
  color: rgb(194, 194, 194);
}
.TC-points {
  margin-left: 40px;
}
.TC-points li {
  margin-bottom: 15px;
  color: rgb(194, 194, 194);
}
.TC-points li span {
  color: red;
  font-weight: 600;
}

.TC-textend {
  margin: 50px 0;
}

@media only screen and (max-width: 600px) {
  .termConditionMain {
    padding: 20px;
  }

  .TC-heading-1 {
    font-size: 1.6rem;
    margin: 0 20px;
  }

  .TC-heading-2 {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .TC-text {
    font-size: 0.9rem;
    margin-left: 10px;
  }

  .TC-points {
    margin-left: 20px;
    font-size: 0.9rem;
  }

  .TC-textend {
    margin: 30px 0;
  }
}

/* ---------------- how why goc ----------------- */


.our-work {
  /* padding-top: 20px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  /* width: 85%; */
  max-width: 1200px;
  gap: 20px;
  margin: auto;
  padding: 60px 0;
  /* text-align: end; */
  /* padding: 40px 0; */
}

.our-work-txt-heading { 
  font-size: 2.5rem;
  font-weight: bold;
  font-family: stylish;
}
.our-work-txt-heading >span{
  color: red;
}

.our-work-img {
  width: 100%;
  /* border: rgb(91, 91, 91) solid; */
  padding: 20px;
  border-radius: 25px;
  display: flex;
  background-color: black;
      box-shadow: 4px 4px 6px 0 #1f1f1fb5, -4px -4px 6px 0 rgba(77, 77, 77, 0.6), inset -4px -4px 6px 0 #000000b2, inset 4px 4px 6px 2px hsla(0, 0%, 13%, 0.5);

}
.our-work-img img {
  width: 100%;
  border-radius: 15px;
  border: rgb(52, 52, 52) solid 2px;
}
.our-work-txt-text {
  text-align: justify;
  padding: 0 10px;
}

.our-work-txt-points {
  padding: 10px 20px;
  display: grid;
  gap: 15px;
}

.our-work-txt-points >div{
  font-size: 1rem;
  font-weight: normal;
}

.our-work-txt-points > div::before {
  content: "\2756";
  font-size: 1.2rem;
  margin-right: 10px;
  color: red;
  text-decoration: none !important ;
}



/* --------------------------------------------------------------------------------- */




@media only screen and (max-width: 600px) {
  

  /* aa----------------------------------------- */
  
.our-work {
  /* width: 100%; */
  /* margin: 10px; */
  grid-template-columns: 1fr;
  margin: 0px 20px;
  padding: 40px 0;
  gap: 0px;
}

.our-work-img {
  /* padding: 10px;
  margin: 15px 0; */

  display: none;
  
}

.our-work-txt-heading{
  padding-left: 5px;
  font-size: 1.7rem;
}
.our-work-txt-text{
  font-size: .77rem;
  color: rgb(206, 206, 206);
}
.our-work-txt-points {
  /* padding: 10px; */
  display: grid;
  gap: 5px;
  font-size: .8rem;
}

/* ---------------- */



  
}

/* ---------- about end ---------- */




.booked-page{
  /* height: 60vh; */
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.booked-page-content{
  background-color: black;
  text-align: center;
  padding: 50px;
}

.booked-page-headding-2{
  font-size: 3rem;
  font-weight: 700;
}


.booked-page-headding-2 span{
  color: red;
  font-size: 3rem;
}


.booked-page-headding-3{
  font-size: 2.5rem;
  color: red;
  font-weight: 600;
}

.booked-page-headding-4 a{
  /* text-decoration: underline; */
  color: red;
}
.booked-page-headding-4{
  font-size: 1.8rem;
}


@media only screen and (max-width: 600px) {
  
.booked-page{
  padding: 40px 20px;
}

.booked-page-content{
  display: grid;
  gap: 10px;
  padding: 10px;
}


.booked-page-headding-2{
  font-size: 1.8rem;
}


.booked-page-headding-2 span{
  color: red;
  font-size: 2rem;
  display: none;
}


.booked-page-headding-3{
  font-size: 1.3rem;
  color: red;
  font-weight: 600;
}

.booked-page-headding-4{
  font-size: 1.3rem;
}




}


