/* ---------- blog start ---------- */

/* .blog-main {
    font-family: poppins;
    padding: 20px 120px;
  }
  .blog-head {
    font-size: 3rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 50px;
  }
  .blog-head > span {
    color: red;
  }
  
  .blog-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px 50px;
  }
  
  .blog-c-col img {
    width: 100%;
    border-radius: 40px;
    border: 3px solid rgb(70, 70, 70);
  }
  
  .blog-c-c-text {
    padding: 20px;
  }
  
  .blog-title {
    font-size: 1.3rem;
    font-weight: 600;
  }
  .blog-text {
    text-align: justify;
    padding: 5px 20px;
    color: rgb(182, 182, 182);
  }
  .blog-c-c-text > hr {
    color: rgb(207, 0, 0);
    border: #676767 solid 1px;
    margin: 1.2rem 0 0.4rem;
    background-color: #2d375e;
  }
  .blog-end {
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
  }
  
  @media only screen and (max-width: 600px) {
    .blog-main {
      padding: 0px 20px;
    }
  
    .blog-head {
      font-size: 2rem;
    }
  
    .blog-content {
      grid-template-columns: 1fr;
      gap: 40px;
    }
  
    .blog-c-col img {
      width: 80%;
      margin: auto;
      display: flex;
      border-radius: 20px;
    }
  } */

/* ---------- blog end ---------- */

.blog-page{
  display: grid;
  grid-template-columns: 2fr 5fr;
  gap: 80px;

}
  
  

.blog-page-col-1 {
  /* position: fixed; */
  /* width: 400px; */
  position: sticky;
  top: 0;

  background-color: black;
  box-shadow: rgba(255, 255, 255, 0.3) 2px 4px 30px;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 30px;
  /* z-index: -1; */
}

.blog-page-col-2 {
  margin: 30px 0;
  /* padding-left: 480px; */
  /* background-color: red; */
  width: 90%;
  /* height: 100vh; */
}

.b-p-c1-h1 {
  font-size: 3rem;
  font-weight: 600;
}

.b-p-c1-h1 span {
  color: red;
}

.b-p-c2-h1 {
  font-size: 2.8rem;
  font-weight: 600;
}
.b-p-c2-h1 span {
  color: red;
}

.b-p-c2-h2 {
  font-size: 1.8rem;
  font-weight: 600;
}
.b-p-c2-h2 span {
  color: red;
  /* color: white; */
}

.s-b-c-img-1 {
  padding: 0 50px;
}

.s-b-c-p-t-button {
  display: block;
  color: red;
  font-size: 1.5rem;
  font-weight: 600;
}

.b-p-rm-button {
  margin: 15px 0px;
}

.b-p-rm-button a {
  background: red;
  color: white;
  text-decoration: none;
  /* margin: 10px 40px; */
  padding: 5px 10px;
  border-radius: 20px;
  border: solid 3px rgb(203, 203, 203);
}



/* ----------------------------------------- */

.blog-component-col-2-pagination{
  display: flex;
  justify-content: center;
  /* margin-top: 50px; */
}
.blog-component-col-2-pagination-buttons{
  display: flex;
  gap: 10px;
}

.blog-component-col-2-pagination-btn{
  border: #fe0000 solid 1px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.blog-component-col-2-pagination-btn-active{
  background-color: #fe0000;
  color: white;
}
.blog-component-col-2-pagination-btn-disable{
  background-color: #2e2e2e;
  color: rgb(202, 202, 202);
  border: none;
  cursor: no-drop;
}   



@media only screen and (max-width: 600px) {
  .blog-page {
    display: grid;
    grid-template-columns: 1fr;
  }
  .blog-page-col-1 {
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 10px;
  }

  .b-p-c1-h1 {
    font-size: 2rem;
    font-weight: 600;
    padding: 20px;
  }

  .blog-page-col-2 {
    width: 100%;
    padding: 20px;
    margin: 0;
  }

  .b-p-c2-head {
    display: none;
  }

  
    /* ---------------------------- */

    
.blog-component-col-2-pagination{   
  margin-top: 30px;
}
.blog-component-col-2-pagination-buttons{
  gap: 7px;
}

.blog-component-col-2-pagination-btn{
  font-size: .9rem;
  width:  30px;
  height: 30px;
}
  
  
}
