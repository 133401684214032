.client-say{
    max-width: 1200px;
    margin: auto ;
    padding: 60px 0;
}
.client-say-heading{
    font-size: 2.8rem;
    font-weight: bold;
    text-align: center;
    font-family: stylish;
}
.client-say-heading>span{
    color: red;
}
.client-say-content{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    /* margin: 20px 0px 0; */
    margin-top: 20px;
    gap: 80px;
}

.client-say-col-img{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.client-say-col-img>img{
    width: 200px;
    /* height: 50px; */
}
.client-say-col{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: rgba(255, 255, 255, 0.2) 2px 4px 10px;
    background-color: black;
    background-color: white;
    box-shadow: 4px 4px 6px 0 #000000b5, -4px -4px 6px 0 rgba(0, 0, 0, 0.6), inset -4px -4px 6px 0 #3f3f3fb2, inset 4px 4px 6px 2px hsla(0, 0%, 25%, 0.5);
    color: black;
    padding: 30px 30px;
    border-radius: 20px;
}
.client-say-col-rating{
    font-size: 1.1rem;
    font-weight: 500;
    margin-bottom: 5px;
}
.client-say-col-rating>span{
    color: red;
    color: #f9cc18;
    font-size: 1.5rem;
}

.client-say-col-link a{
    color: white;
    color: red;
    font-weight: 600;
    font-size: 1.2rem;
    
}
.client-say-col-link a:hover{
color: rgb(151, 0, 0);
}
@media only screen and (max-width: 800px) {
    .client-say{
        margin: 0px auto;
  padding: 40px 0;
        
    }
    .client-say-heading{
        font-size: 1.9rem;
    }

    .client-say-content{
      display: flex;
      gap: 20px;
      overflow: auto;
      padding: 0px 20px;
    }
    .client-say-col-img{
        height: auto;
    }
    
    .client-say-col-img>img{
        width: 120px;
        /* height: 50px; */
    }
    .client-say-col{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px 10px;
    }
    .client-say-col-rating{
        font-size: 1rem;
        font-weight: 500;
        margin-bottom: 5px;
        min-width: 240px;
    }
    .client-say-col-rating>span{
        font-size: 1.1rem;
    }
    
    .client-say-col-link a{
        /* color: red; */
        font-weight: 500;
        font-size: 1rem;
        
    }
    
    
    
}

